import { BaseModel } from 'sjs-base-model';
import Game from './Game';
import Player from './Player';
import Result from './Result';

export default class Participation extends BaseModel {

    constructor(data) {
        super();
        this.player = new Player(data.player);
        this.game = new Game(data.game);
        this.result = new Result(data.result);
        this.city = '';
        this.update(data);
    }
}