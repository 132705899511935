import React, { useEffect, useState } from 'react';
import BaseContainer from './higher_order/BaseContainer';
import { Grid } from '@material-ui/core';
import { ADMIN } from '../constants/i18n';
import { connect } from 'react-redux';
import LoginForm from '../components/AdminPanel/LoginForm';
import * as AuthAction from '../stores/auth/AuthAction';
import * as NamesakesAction from '../stores/namesakes/NamesakesAction';
import * as RanksAction from '../stores/ranks/RanksAction';
import AuthSelector from '../selectors/AuthSelector';
import LogoutBtn from '../components/AdminPanel/LogoutBtn';
import Routes from '../constants/Routes';
import DangerZone from '../components/AdminPanel/DangerZone';
import LanguageSelector from '../selectors/LanguageSelector';
import RanksSelector from '../selectors/RanksSelector';
import CircularProgressWithLabel from '../components/AdminPanel/CircularProgressWithLabel';
import { DEFAULT_PAGE_SIZE, DEFAULT_RECALCULATE_REELO_PAGE_SIZE } from '../constants/pagination';


const mapStateToProps = (state) => ({
    isAuthenticated: AuthSelector.selectIsAuthenticated(state),
    language: LanguageSelector.selectLanguage(state),
    playersCount: RanksSelector.selectPlayersCount(state),
    isSelected: AuthSelector.selectInternationalOnly(state),
});

const Admin = (props) => {

    useEffect(() => {
        props.dispatch(RanksAction.countPlayers());
    }, []);

    const { isSelected, isAuthenticated, language, playersCount } = props;

    const [percentage, setPercentage] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(0);
    const [displayProgress, setDisplayProgress] = useState(false);

    const onLogin = (email, password) => {
        props.dispatch(AuthAction.login(email, password));
        if (isAuthenticated) {
            props.history.push(Routes.admin);
        }
    };

    const onLogout = () => {
        props.dispatch(AuthAction.logout());
        props.history.push(Routes.admin);
    };


    const resetPercentage = (fx) => () => {
        setPercentage(0);
        fx();
    };

    const onRebuild = async () => {
        setDisplayProgress(false);
        await props.dispatch(AuthAction.rebuildDB());
    };

    const onRecalculateReelo = async () => {
        setDisplayProgress(true);
        setSize(DEFAULT_RECALCULATE_REELO_PAGE_SIZE);
        await props.dispatch(AuthAction.recalculateReeloPaginated(page, DEFAULT_RECALCULATE_REELO_PAGE_SIZE, playersCount, setPercentage, setPage));
    };

    const onRecalculatePseudo = async () => {
        setDisplayProgress(true);
        setSize(DEFAULT_PAGE_SIZE);
        await props.dispatch(AuthAction.recalculatePseudoReeloPaginated(page, DEFAULT_PAGE_SIZE, playersCount, setPercentage, setPage));
    };

    const onRecalculateInit = async () => {
        setDisplayProgress(true);
        setSize(DEFAULT_PAGE_SIZE);
        await props.dispatch(AuthAction.recalculateInitReeloPaginated(page, DEFAULT_PAGE_SIZE, playersCount, setPercentage, setPage));
    };

    const onMerge = (src, tgt) => async () => {
        await props.dispatch(NamesakesAction.merge(src, tgt));
    };

    const onPositions = async () => {
        await props.dispatch(AuthAction.calculatePositions());
    };

    const onLoading = () => (<CircularProgressWithLabel value={percentage} current={(page - 1) * size} total={playersCount} />);

    const onSwitch = isChecked => props.dispatch(AuthAction.switchInternationalOnly(isChecked));

    return (
        <BaseContainer title={ADMIN[language].TITLE} overrideLoadingFx={displayProgress ? onLoading : false}>
            <Grid container spacing={10}>
                {isAuthenticated ? <LogoutBtn onLogout={onLogout} /> : <LoginForm onLogin={onLogin} />}
                {isAuthenticated &&
                    <DangerZone
                        page={page}
                        onChange={setPage}
                        onRebuild={onRebuild}
                        onRecalculateReelo={resetPercentage(onRecalculateReelo)}
                        onRecalculatePseudo={resetPercentage(onRecalculatePseudo)}
                        onRecalculateInit={resetPercentage(onRecalculateInit)}
                        textResource={ADMIN[language].DANGER_ZONE}
                        onMerge={onMerge}
                        onPositions={onPositions}
                        onSwitch={onSwitch}
                        isChecked={isSelected}
                    />}
            </Grid>
        </BaseContainer>
    );
};


export default connect(mapStateToProps)(Admin);
