import Action from '../../utils/Action';
import NamesakesEffects from './NamesakesEffects';

export const FETCH_NAMESAKES = 'Namesakes.fetch';
export const FETCH_NAMESAKES_COMPLETE = `${FETCH_NAMESAKES}${Action.DONE_SUFFIX}`;
export const FETCH_NAMESAKES_LOADING = `${FETCH_NAMESAKES}${Action.LOADING_SUFFIX}`;

export const fetch = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, FETCH_NAMESAKES, NamesakesEffects.fetch, token);
};

export const MERGE_NAMESAKES = 'Namesakes.merge';
export const MERGE_NAMESAKES_COMPLETE = `${MERGE_NAMESAKES}${Action.DONE_SUFFIX}`;
export const MERGE_NAMESAKES_LOADING = `${MERGE_NAMESAKES}${Action.LOADING_SUFFIX}`;

export const merge = (src, tgt) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, MERGE_NAMESAKES, NamesakesEffects.merge, token, src, tgt);
};

export const SOLVE_NAMESAKES = 'Namesakes.solve';
export const SOLVE_NAMESAKES_COMPLETE = `${SOLVE_NAMESAKES}${Action.DONE_SUFFIX}`;
export const SOLVE_NAMESAKES_LOADING = `${SOLVE_NAMESAKES}${Action.LOADING_SUFFIX}`;

export const solve = (namesake, player, participation) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, SOLVE_NAMESAKES, NamesakesEffects.solve, token, namesake, player, participation);
};

export const SOLVE_TO_NEW_NAMESAKES = 'Namesakes.solve';
export const SOLVE_TO_NEW_NAMESAKES_COMPLETE = `${SOLVE_TO_NEW_NAMESAKES}${Action.DONE_SUFFIX}`;
export const SOLVE_TO_NEW_NAMESAKES_LOADING = `${SOLVE_TO_NEW_NAMESAKES}${Action.LOADING_SUFFIX}`;
export const solveToNew = (namesake, participation) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, SOLVE_TO_NEW_NAMESAKES, NamesakesEffects.solveToNew, token, namesake, participation);
};