export const toTitleCase = (s = '') => {
    const split = s.split(' ');
    var res = '';
    split.forEach((word, i) => res += word.charAt(0).toUpperCase() + word.substring(1, s.length) + (i !== split.length -1 ? ' ' : ''));

    return res;
};

export const fiveRelevantDigits = (n) => Number.isInteger(n) ? n : n.toFixed(5 - n.toString().split('.')[0].length < 0 ? 0 : 5 - n.toString().split('.')[0].length);


export const parseQs = (qs) => {
    const obj = {};
    for (const keyValue of qs.split('?')[1].split('&')) {
        const [k, v] = keyValue.split('=');
        obj[k] = v;
    }

    return obj;
};