import { Grid, Typography, makeStyles } from '@material-ui/core';
import RanksTable from '../Ranks/RanksTable';
import SearchBar from '../Ranks/SearchBar';
import { RANKS, PROOF } from '../../constants/i18n';
import { DEFAULT_PAGE_FETCH_SIZE } from '../../constants/pagination';

import * as RanksAction from '../../stores/ranks/RanksAction';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    searchBar: {},
    container: {
        height: '50px',
        width: '100%'
    },
}));

const Step0 = (props) => {

    const { language, dispatch, error, searching, selectedPlayer, ranksTable, pagination, internationalOnly, playersCount, setSearching, setSelectedPlayer } = props;
    const classes = useStyles();

    const [filter, setFilter] = useState(RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES);


    const onPageChange = (_, page) => {
        dispatch(RanksAction.setPage(page + 1));
        dispatch(RanksAction.fetch());
    };

    const onSizeChange = (event) => {
        dispatch(RanksAction.setPage(1));
        const size = parseInt(event?.target.value, 10);
        dispatch(RanksAction.setSize(size));
        dispatch(RanksAction.fetch());
    };

    const onSearch = (name, surname, id) => {
        dispatch(RanksAction.filter(name, surname, id));
        setSearching(true);
    };

    const onClear = () => {
        if (searching) {
            setSearching(false);
            dispatch(RanksAction.setPage(1));
            dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
            dispatch(RanksAction.fetch());
        }
    };

    const onRowClick = async (player) => {
        setSelectedPlayer(player);
    };

    const onFilter = c => {
        setSearching(true);
        setFilter(c);
        dispatch(RanksAction.setPage(1));
        if (c === RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES) {
            onClear();
            return;
        }
        dispatch(RanksAction.filterByCat(c));
    };


    const content = selectedPlayer ?
        (<Typography>{PROOF[language].INSTRUCTIONS[0].SELECTED} <b>{`${selectedPlayer.name} ${selectedPlayer.surname}`}</b> </Typography>) :
        (<Grid item container xs={12}>
            <SearchBar
                hideClear
                hideCategory
                hideID
                className={classes.searchBar}
                language={RANKS[language].SEARCH_OPTIONS}
                filter={filter}
                onFilter={onFilter}
                onSearch={onSearch}
                onClear={onClear} />
            <br />
            <RanksTable
                hidden={!searching || error}
                showNationality={internationalOnly}
                onRowClick={onRowClick}
                rows={ranksTable.rows}
                columns={ranksTable.columns}
                page={pagination.page}
                pageSize={pagination.size}
                onPageChange={onPageChange}
                onSizeChange={onSizeChange}
                entriesCount={playersCount}
                labelDisplayedRows={ranksTable.labelDisplayedRows}
                labelRowsPerPage={RANKS[language].RANKS_TABLE_FOOTER.LABEL_ROWS_PER_PAGE}
            />
        </Grid>);


    return content;

};

export default Step0;