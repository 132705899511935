import HttpErrorModel from '../../models/HttpErrorModel';
import Action from '../../utils/Action';
import AuthEffects from './AuthEffects';

export const AUTH_LOGIN = 'Auth.login';
export const AUTH_LOGIN_COMPLETE = `${AUTH_LOGIN}${Action.DONE_SUFFIX}`;
export const AUTH_LOGIN_LOADING = `${AUTH_LOGIN}${Action.LOADING_SUFFIX}`;

export const login = (email, password) => async (dispatch) => {
    await Action.createThunk(dispatch, AUTH_LOGIN, AuthEffects.login, email, password);
};

export const AUTH_LOGOUT = 'Auth.logout';
export const logout = () => Action.createDefault(AUTH_LOGOUT);

export const AUTH_REBUILD_DB = 'Auth.rebuildDB';
export const AUTH_REBUILD_DB_COMPLETE = `${AUTH_REBUILD_DB}${Action.DONE_SUFFIX}`;
export const AUTH_REBUILD_DB_LOADING = `${AUTH_REBUILD_DB}${Action.LOADING_SUFFIX}`;

export const rebuildDB = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, AUTH_REBUILD_DB, AuthEffects.rebuildDB, token);
};

export const AUTH_CALCULATE_REELO = 'Auth.calculateReelo';
export const AUTH_CALCULATE_REELO_COMPLETE = `${AUTH_CALCULATE_REELO}${Action.DONE_SUFFIX}`;
export const AUTH_CALCULATE_REELO_LOADING = `${AUTH_CALCULATE_REELO}${Action.LOADING_SUFFIX}`;

export const recalculateReeloPaginated = (page, size, total, setPercentage, setPage) => async (dispatch, getState) => {
    const {internationalOnly, token} = getState().auth;
    if (size > total) {
        size = total;
    }
    for (let p = page || 1; (p-1) * size <= total; p++) {
        const model = await Action.createThunk(dispatch, AUTH_CALCULATE_REELO, AuthEffects.recalculateReelo, token, p, size, internationalOnly);
        if (model instanceof HttpErrorModel) {
            break;
        }

        setPercentage(((p-1) * size / total).toFixed(4) * 100);
        setPage(p);
    }
};

export const AUTH_CALCULATE_PSEUDO_REELO = 'Auth.calculatePseudoReelo';
export const AUTH_CALCULATE_PSEUDO_REELO_COMPLETE = `${AUTH_CALCULATE_PSEUDO_REELO}${Action.DONE_SUFFIX}`;
export const AUTH_CALCULATE_PSEUDO_REELO_LOADING = `${AUTH_CALCULATE_PSEUDO_REELO}${Action.LOADING_SUFFIX}`;

export const recalculatePseudoReeloPaginated = (page, size, total, setPercentage, setPage) => async (dispatch, getState) => {
    const {internationalOnly, token} = getState().auth;
    if (size > total) {
        size = total;
    }
    for (let p = page || 1; (p-1) * size <= total; p++) {
        const model = await Action.createThunk(dispatch, AUTH_CALCULATE_PSEUDO_REELO, AuthEffects.recalculatePseudoReelo, token, p, size, internationalOnly);
        if (model instanceof HttpErrorModel) {
            break;
        }

        setPercentage(((p-1) * size / total).toFixed(4) * 100);
        setPage(p);
    }
};

export const AUTH_CALCULATE_INIT_REELO = 'Auth.calculateInitReelo';
export const AUTH_CALCULATE_INIT_REELO_COMPLETE = `${AUTH_CALCULATE_INIT_REELO}${Action.DONE_SUFFIX}`;
export const AUTH_CALCULATE_INIT_REELO_LOADING = `${AUTH_CALCULATE_INIT_REELO}${Action.LOADING_SUFFIX}`;

export const recalculateInitReeloPaginated = (page, size, total, setPercentage, setPage) => async (dispatch, getState) => {
    const {internationalOnly, token} = getState().auth;
    if (size > total) {
        size = total;
    }
    for (let p = page || 1; (p-1) * size <= total; p++) {
        const model = await Action.createThunk(dispatch, AUTH_CALCULATE_INIT_REELO, AuthEffects.recalculateInitReelo, token, p, size, internationalOnly);
        if (model instanceof HttpErrorModel) {
            break;
        }

        setPercentage(((p-1) * size / total).toFixed(4) * 100);
        setPage(p);
    }
};


export const AUTH_CALCULATE_POSITIONS = 'Auth.calculatePositions';
export const AUTH_CALCULATE_POSITIONS_COMPLETE = `${AUTH_CALCULATE_POSITIONS}${Action.DONE_SUFFIX}`;
export const AUTH_CALCULATE_POSITIONS_LOADING = `${AUTH_CALCULATE_POSITIONS}${Action.LOADING_SUFFIX}`;

export const calculatePositions = () => async (dispatch, getState) => {
    const {internationalOnly, token} = getState().auth;
    await Action.createThunk(dispatch, AUTH_CALCULATE_POSITIONS, AuthEffects.calculatePositions, token, internationalOnly);
};


export const AUTH_SWITCH_INTERNATIONAL_ONLY = 'Auth.switchInternationalOn';
export const switchInternationalOnly = (isChecked) => Action.createDefault(AUTH_SWITCH_INTERNATIONAL_ONLY, {isChecked});