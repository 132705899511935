import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';

const TextFieldWithDescription = (props) => {

    const { descriptionOnTop, description, label, value, onChange, descriptionValue, required } = props;

    const renderDescription = () => description ? (
        <Typography variant="body1">
            <i>{description}:</i> {descriptionValue}
        </Typography>
    ): null;

    return (
        <Grid item xs={12}>
            {descriptionOnTop && renderDescription()}

            <TextField
                variant="outlined"
                fullWidth
                value={value}
                label={label}
                onChange={onChange}
                required={required}
            />

            {!descriptionOnTop && renderDescription()}

        </Grid>
    );
};

export default TextFieldWithDescription;