import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';

const ConfirmDialog = props => {

    const { title, message, open, onConfirm, onCancel, confirmOpt, cancelOpt, confirmDisabled } = props;
    const [innerOpen, setOpen] = useState(open);

    const handleConfirm = () => {
        setOpen(false);
        onConfirm();
    };

    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const lines = message.split('\n').map(l => <p key={l}>{l}</p>);
    return (
        <Dialog fullWidth open={innerOpen} onClose={handleCancel}>
            <DialogTitle > {title} </DialogTitle>
            <DialogContent dividers> {lines} </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' onClick={handleConfirm} disabled={confirmDisabled}>{confirmOpt}</Button>
                <Button onClick={handleCancel}>{cancelOpt}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;