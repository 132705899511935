import { DEFAULT_PAGE, DEFAULT_PAGE_FETCH_SIZE } from '../../constants/pagination';
import * as RanksAction from './RanksAction';

const INITIAL_STATE = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_FETCH_SIZE,
    playersCount: 0,
    rows: [],
    details: [],
    steps: {}
};

const RanksReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [RanksAction.FETCH_RANKS_COMPLETE]: { ...state, rows: action.payload },
        [RanksAction.SET_RANKS_TABLE_PAGE]: { ...state, page: action.payload?.page },
        [RanksAction.SET_RANKS_TABLE_SIZE]: { ...state, size: action.payload?.size },
        [RanksAction.COUNT_PLAYERS_COMPLETE]: { ...state, playersCount: action.payload?.count },
        [RanksAction.FILTER_RANKS_COMPLETE]: {...state, playersCount: action.payload?.length, rows: action.payload, page: 1, size: action.payload?.length},
        [RanksAction.FETCH_RANKS_DETAILS_COMPLETE]: {...state, details: action.payload},
        [RanksAction.FETCH_REELO_STEPS_COMPLETE]: {...state, steps: action.payload},
        [RanksAction.FILTER_RANKS_BY_CAT_COMPLETE]: {...state, playersCount: action.payload?.length, rows: action.payload, page: 1, size: action.payload?.length} 

    };

    return reduce[action.type] || { ...INITIAL_STATE, ...state };
};

export default RanksReducer;
