import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BaseContainer from './higher_order/BaseContainer';
import { RANKS } from '../constants/i18n';
import LanguageSelector from '../selectors/LanguageSelector';
import * as RanksAction from '../stores/ranks/RanksAction';
import DetailsContainer from '../components/Ranks/DetailsContainer';
import RanksSelector from '../selectors/RanksSelector';
import AuthSelector from '../selectors/AuthSelector';
import { parseQs } from '../utils/common';
import Routes from '../constants/Routes';
import { Typography } from '@material-ui/core';

const mapStateToProps = (state) => ({
    language: LanguageSelector.selectLanguage(state),
    details: RanksSelector.selectPlayerDetails(state),
    steps: RanksSelector.selectReeloSteps(state),
    isAuthenticated: AuthSelector.selectIsAuthenticated(state)
});

const Details = (props) => {

    const { language, details, steps, isAuthenticated } = props;


    if (props.location.search.length <= 0) {
        props.history.push(Routes.ranks);
        return '=(';
    }

    const player = parseQs(props.location.search);

    if (!(player.id && player.name && player.surname)) {
        props.history.push(Routes.ranks);
        return '=(';
    }

    useEffect(() => { props.dispatch(RanksAction.details(player.id)); }, []);

    const onReelo = async () => {
        await props.dispatch(RanksAction.reeloSteps(player.id));
        window.scrollTo(0, 500);
    };

    const description = <Typography>
        {RANKS[language].DETAILS.DESCRIPTION.INTRO} <br/>
        {RANKS[language].DETAILS.DESCRIPTION.PLAYER + ': '} <b> {decodeURI(player.name) + ' ' + decodeURI(player.surname)} </b> <br/>
        {RANKS[language].DETAILS.DESCRIPTION.POSITION + ': '} <b>{decodeURI(player.position)}</b>
        <br />
        {RANKS[language].DETAILS.DESCRIPTION.PLEASE_SCROLL}
    </Typography>;

    return (
        <BaseContainer title={RANKS[language].DETAILS.TITLE} description={description}>
            <DetailsContainer
                isAuthenticated={isAuthenticated}
                steps={steps}
                language={RANKS[language].DETAILS}
                columns={details.columns}
                history={details.history}
                onReelo={onReelo}
            />
        </BaseContainer>
    );
};

export default connect(mapStateToProps)(Details);