import { Grid } from '@material-ui/core';
import { compose } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NamesakeSolver from '../components/Namsake/NamesakeSolver';
import { NAMESAKES } from '../constants/i18n';
import LanguageSelector from '../selectors/LanguageSelector';
import NamesakesSelector from '../selectors/NamesakesSelector';
import BaseContainer from './higher_order/BaseContainer';
import RequireAuth from './higher_order/RequireAuth';
import * as NamesakesAction from '../stores/namesakes/NamesakesAction';
import ErrorSelector from '../selectors/ErrorSelector';

const mapStateToProps = (state) => ({
    language: LanguageSelector.selectLanguage(state),
    namesakes: NamesakesSelector.selectNamesakes(state),
    error: ErrorSelector.selectError(state)
});

const Namesakes = props => {

    const { language, namesakes, error } = props;

    const [resolving, setResolving] = useState(false);

    useEffect(() => {
        if (!error && !resolving) {
            props.dispatch(NamesakesAction.fetch());
        }
    }, []);

    const handleSolve = async (namesake, player, participation) => {
        if (!window.confirm('NE SEI SICURO??')) {
            return;
        }
        setResolving(true);
        await props.dispatch(NamesakesAction.solve(namesake.id, player.id, participation.id));
    };

    const handleSolveToNew = async (namesake, participation) => {
        if (!window.confirm('NE SEI SICURO??')) {
            return;
        }
        setResolving(true);
        await props.dispatch(NamesakesAction.solveToNew(namesake.id, participation.id));
    };

    const renderNamesakes = () => namesakes.map((np, i) => (
        <NamesakeSolver
            namesake={np.namesake_player}
            namesakeParticipation={np}
            possibilities={np.namesake_player.possible_solutions}
            key={i}
            labels={NAMESAKES[language].LABELS}
            btnSolve={NAMESAKES[language].BTN_SOLVE}
            btnNewSolve={NAMESAKES[language].BTN_SOLVE_TO_NEW}
            onSolve={handleSolve}
            onSolveToNew={handleSolveToNew}
        />
    ));

    return (
        <BaseContainer title={NAMESAKES[language].TITLE} description={NAMESAKES[language].DESCRIPTION} skipLoading>
            <br />
            <Grid item container xs={12} justifyContent='center' spacing={3}>
                {renderNamesakes()}
            </Grid>
        </BaseContainer>
    );
};

export default compose(RequireAuth, connect(mapStateToProps))(Namesakes);
