import * as NavigationActions from './NavigationAction';


const INITIAL_STATE = {
    location: `${window.location.pathname}${window.location.search}`,
    prevLocation: `${window.location.pathname}${window.location.search}`
};


const NavigationReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [NavigationActions.SET_NAV_LOCATION]: {...state, location: action.payload, prevLocation: state.location}
    };

    return reduce[action.type] || {...INITIAL_STATE, ...state};
};

export default NavigationReducer;