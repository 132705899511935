import { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Ranks from './containers/Ranks';
import React from 'react';
import Routes from './constants/Routes';
import Home from './containers/Home';
import TopNavigation from './components/Navigation/TopNavigation';
import BottomNavigation from './components/Navigation/BottomNavigation';
import './index.css';
import Upload from './containers/Upload';
import Algorithm from './containers/Algorithm';
import Namesakes from './containers/Namesakes';
import Admin from './containers/Admin';
import Details from './containers/Details';
import Proof from './containers/Proof';

class Router extends Component {

    render() {
        return (
            <BrowserRouter>
                <div >
                    <TopNavigation />
                    <Route exact path={Routes.home} component={Home} dispatch={this.props.dispatch} />
                    <Route exact path={Routes.ranks} component={Ranks} dispatch={this.props.dispatch} />
                    <Route exact path={Routes.upload} component={Upload} dispatch={this.props.dispatch} />
                    <Route exact path={Routes.algorithm} component={Algorithm} dispatch={this.props.dispatch} />
                    <Route exact path={Routes.namesakes} component={Namesakes} dispatch={this.props.dispatch} />
                    <Route exact path={Routes.admin} component={Admin} dispatch={this.props.dispatch} />
                    <Route path={Routes.details} component={Details} dispatch={this.props.dispatch} />
                    <Route path={Routes.proof} component={Proof} dispatch={this.props.dispatch} />
                    <div style={{ height: '200px' }}></div>
                    <BottomNavigation />
                </div>
            </BrowserRouter>
        );
    }
}

export default Router;