import { Button, Grid, Switch, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

const DangerZone = (props) => {

    const { onPositions, onMerge, onRebuild, onRecalculateReelo, onRecalculatePseudo, onRecalculateInit, textResource, onChange, onSwitch, isChecked, page } = props;

    const [src, setSrc] = useState(0);
    const [tgt, setTgt] = useState(0);

    const handleClick = (fx) => () => {
        const confirmed = window.confirm(textResource.CONFIRM_TEXT);
        if (confirmed) {
            fx();
        }
    };

    const handleChange = (fx) => (event) => {
        const p = event.target.value;
        if (isNaN(p)) {
            return;
        }
        fx(p);
    };

    const handleSwitch = (event) => {
        onSwitch(event.target.checked);
    };

    return (
        <Grid item container xs={12} justifyContent='center' spacing={1}>
            <Grid item container xs={12} direction='column' justifyContent='center' alignItems='flex-start' spacing={1}>
                <Grid item xs={4}>
                    <Switch checked={isChecked} color="primary" onChange={handleSwitch}/> {isChecked ? textResource.SWITCH_INTERNATIONAL_OFF : textResource.SWITCH_INTERNATIONAL_ON}
                </Grid>
                <Grid item xs={4}>
                    <Button variant='contained' color='primary' onClick={handleClick(onRebuild)}>
                        {textResource.REBUILD_DB}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant='contained' color='primary' onClick={handleClick(onPositions)}>
                        {textResource.POSITIONS}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <TextField value={page} label={textResource.PAGE} onChange={handleChange(onChange)}></TextField>
                </Grid>

                <Grid item xs={3}>
                    <Button variant='contained' color='primary' onClick={handleClick(onRecalculateInit)}>
                        {textResource.FORCE_INIT_REELO}
                    </Button>
                </Grid>

                <Grid item xs={3}>
                    <Button variant='contained' color='primary' onClick={handleClick(onRecalculatePseudo)}>
                        {textResource.FORCE_PSEUDO_REELO}
                    </Button>
                </Grid>

                <Grid item xs={3}>
                    <Button variant='contained' color='primary' onClick={handleClick(onRecalculateReelo)}>
                        {textResource.FORCE_REELO}
                    </Button>
                </Grid>
            </Grid >

            <Grid item container xs={12} spacing={4}>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={12}>
                    <Typography> {textResource.IGNORE}</Typography>
                </Grid>

                <Grid item xs={3}>
                    <TextField fullWidth value={src} label={textResource.SRC} onChange={handleChange(setSrc)}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth value={tgt} label={textResource.TGT} onChange={handleChange(setTgt)}></TextField>
                </Grid>

                <Grid item xs={3}>
                    <Button variant='contained' color='primary' onClick={onMerge(src, tgt)}>
                        {textResource.MERGE}
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    );
};

export default DangerZone;