import { BaseModel } from 'sjs-base-model';

export default class Game extends BaseModel {


    constructor(data) {
        super();
        this.id = 0;
        this.year = 0;
        this.category = '';
        this.start = 0;
        this.end = 0;
        this.isInternational = false;
        this.update(data);
    }
}