import { BaseModel } from 'sjs-base-model';
import { endpoints } from '../../constants/endpoints';
import FetchAlgorithmModel from '../../models/algorithm/FetchAlgorithmModel';
import Model from '../../utils/Model';

const fetch = async (token) => await Model.fromGet(FetchAlgorithmModel, endpoints.Constants, { auth: token });

const update = async (constants, token) => {
    const data = {};
    Object.keys(constants).forEach(k => (data[k] = parseFloat((constants[k] + '').replace(',', '.'))));

    const params = {
        auth: token,
        data: data,
    };
    return await Model.fromPatch(BaseModel, endpoints.Constants, params);
};

export default { fetch, update };