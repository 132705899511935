import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React from 'react';
import { DEFAULT_PAGE_FETCH_SIZE } from '../../constants/pagination';

const useStyles = makeStyles((theme) => ({
    hideOnSmall: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,

    },
    container: {
        maxHeight: '60vh',
        width: '100%'
    },
    hover: {
        cursor: 'pointer'
    },
    hidden: {
        display: 'none'
    }
}));

const RanksTable = props => {
    const { hidden, hidePositionInCategory, rows, columns, pageSize, page, onPageChange, onSizeChange, entriesCount, labelDisplayedRows, labelRowsPerPage, onRowClick, showNationality } = props;

    if (hidden) {
        return null;
    }
    const classes = useStyles();


    const renderHeader = () =>
        columns.map((column, i) => {
            if (i === 4 && !showNationality) {
                return null;
            }
            if (i === 1 && hidePositionInCategory) {
                return null;
            }
            return (
                <TableCell className={`${classes.root} ${column.hideOnSmall ? classes.hideOnSmall : ''}`} align={column.align} key={column.headerName}>
                    {column.headerName}
                </TableCell>
            );
        });

    const renderRows = () =>
        rows.map((row) => (
            <TableRow onClick={() => onRowClick(row)} hover key={row.id} className={classes.hover}>
                <TableCell padding='checkbox' className={classes.hideOnSmall} align={columns[0].align}> {row.position}</TableCell>
                <TableCell width={'100px'} className={hidePositionInCategory ? classes.hidden : classes.hideOnSmall} align={columns[1].align}> {row.filtered_position}</TableCell>
                <TableCell align={columns[2].align}>{row.name}</TableCell>
                <TableCell align={columns[3].align}>{row.surname}</TableCell>
                {showNationality && <TableCell className={classes.hideOnSmall} align={columns[4].align}>{row.nationality}</TableCell>}
                <TableCell padding='checkbox' className={classes.hideOnSmall} align={columns[5].align}>{row.lastCategory}</TableCell>
                <TableCell align={columns[6].align}>{row.reelo}</TableCell>
            </TableRow>
        ));

    return (
        <Paper className={classes.container}>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>{renderHeader()}</TableRow>
                    </TableHead>
                    <TableBody>
                        {renderRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component='div'
                rowsPerPageOptions={[DEFAULT_PAGE_FETCH_SIZE, DEFAULT_PAGE_FETCH_SIZE * 10, DEFAULT_PAGE_FETCH_SIZE * 50, entriesCount]}
                page={page - 1}
                rowsPerPage={pageSize}
                count={entriesCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onSizeChange}
                labelDisplayedRows={labelDisplayedRows(page)}
                labelRowsPerPage={labelRowsPerPage}
            />
        </Paper>
    );
};




export default RanksTable;