import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Icon } from '@material-ui/core';
import { Email, PrivacyTip } from '@mui/icons-material';
import { COMMON } from '../constants/i18n';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
        marginTop: 'auto',
        width: '75%'
    },
    icon: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    link: {
        paddingTop: 3,
        color: theme.palette.text.primary,
    },
    linkContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Footer = (props) => {
    const classes = useStyles();

    const {language} = props; 
    return (
        <footer className={classes.footer}>
            <Grid container spacing={6} justifyContent='center' alignItems='center'>
                <Grid item xs={6} className={classes.linkContainer}>
                    <a className={classes.link} href='./privacy_info.pdf'><Icon className={classes.icon}><PrivacyTip/></Icon></a>
                    <Typography variant="body2" color="textSecondary" align="center">
                        <Link href="./privacy_info.pdf">{COMMON[language].FOOTER.PRIVACY_POLICY}</Link>
                    </Typography>
                </Grid>
                <Grid item xs={6}  alignItems='center' className={classes.linkContainer} >
                    <Icon className={classes.icon}><Email/></Icon>
                    <img src='email.png'/>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;

