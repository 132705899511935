import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import TextFieldWithDescription from './TextFieldWithDescription';

const ALGORITHM = {
    IT: {
        START_YEAR: 'Anno di inizio',
        K_EXERCISE: 'Coefficiente esercizi (K)',
        P_INTERNATIONAL: 'Coefficiente finali internazionali (P)',
        F_MULTIPLIER: 'Fattore moltiplicativo (F)',
        ANTI_EXPLOIT: 'Anti exploit (AE)',
        NO_PARTICIPATION_PENALTY: 'Penalità di non-partecipazione',
        CURRENT_VALUE_DESCRIPTION: 'Valore attuale',
        SUBMIT: 'Aggiorna Variabili',
    }
};

const language = 'IT';

const AlgorithmForm = props => {

    const { currentValues, onSubmit } = props;

    const [year, setYear] = useState(currentValues.year);
    const [kExercise, setKExercise] = useState(currentValues.kExercise);
    const [pInternational, setPInternational] = useState(currentValues.pInternational);
    const [fMultiplier, setFMultiplier] = useState(currentValues.fMultiplier);
    const [noParticipationPenalty, setNoParticipationPenalty] = useState(currentValues.noParticipationPenalty);

    const onChange = fx => event => {
        const value = event.target.value;
        if (new RegExp(/^\d*(.|,)?\d*$/).test(value)) {
            fx(value);
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        onSubmit({ year, kExercise, pInternational, fMultiplier, noParticipationPenalty });
    };

    const shouldDisable =
        year === '' ||
        kExercise === '' ||
        pInternational === '' ||
        fMultiplier === '' ||
        noParticipationPenalty === '';

    return (
        <form onSubmit={handleSubmit}>
            <Grid item container alignContent='center' alignItems="center" spacing={7} xs={12}>


                <TextFieldWithDescription
                    label={ALGORITHM[language].START_YEAR}
                    required
                    value={year}
                    onChange={onChange(setYear)}
                    description={ALGORITHM[language].CURRENT_VALUE_DESCRIPTION}
                    descriptionValue={currentValues.year}
                />

                <TextFieldWithDescription
                    label={ALGORITHM[language].K_EXERCISE}
                    required
                    value={kExercise}
                    onChange={onChange(setKExercise)}
                    description={ALGORITHM[language].CURRENT_VALUE_DESCRIPTION}
                    descriptionValue={currentValues.kExercise}
                />

                <TextFieldWithDescription
                    label={ALGORITHM[language].P_INTERNATIONAL}
                    required
                    value={pInternational}
                    onChange={onChange(setPInternational)}
                    description={ALGORITHM[language].CURRENT_VALUE_DESCRIPTION}
                    descriptionValue={currentValues.pInternational}
                />

                <TextFieldWithDescription
                    label={ALGORITHM[language].F_MULTIPLIER}
                    required
                    value={fMultiplier}
                    onChange={onChange(setFMultiplier)}
                    description={ALGORITHM[language].CURRENT_VALUE_DESCRIPTION}
                    descriptionValue={currentValues.fMultiplier}
                />

                <TextFieldWithDescription
                    label={ALGORITHM[language].NO_PARTICIPATION_PENALTY}
                    required
                    value={noParticipationPenalty}
                    onChange={onChange(setNoParticipationPenalty)}
                    description={ALGORITHM[language].CURRENT_VALUE_DESCRIPTION}
                    descriptionValue={currentValues.noParticipationPenalty}
                />

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={shouldDisable}>{ALGORITHM[language].SUBMIT}</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AlgorithmForm;