import { BaseModel } from 'sjs-base-model';
import { endpoints } from '../../constants/endpoints';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import LoginModel from '../../models/auth/LoginModel';
import Model from '../../utils/Model';

const parseInternationalOnly = (internationalOnly) => `&type=${internationalOnly ? 'international' : 'national-italy'}`;

const login = async (email, password) => {
    const params = {
        data: {
            email,
            password,
        }
    };

    return await Model.fromPost(LoginModel, endpoints.UserLogin, params);
};

const rebuildDB = async (token) => {
    return await Model.fromPost(BaseModel, endpoints.Rebuild, { auth: token });
};

const recalculateReelo = async (token, page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE, internationalOnly) => {
    return await Model.fromPost(BaseModel, `${endpoints.ReeloCalculate.Full}?page=${page}&size=${size}${parseInternationalOnly(internationalOnly)}`, { auth: token });
};

const recalculatePseudoReelo = async (token, page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE, internationalOnly) => {
    return await Model.fromPost(BaseModel, `${endpoints.ReeloCalculate.Pseudo}?page=${page}&size=${size}${parseInternationalOnly(internationalOnly)}`, { auth: token });
};

const recalculateInitReelo = async (token, page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE, internationalOnly) => {
    return await Model.fromPost(BaseModel, `${endpoints.ReeloCalculate.Init}?page=${page}&size=${size}${parseInternationalOnly(internationalOnly)}`, { auth: token });
};

const calculatePositions = async (token, internationalOnly) => {
    return await Model.fromPatch(BaseModel, `${endpoints.PlayersPositions}`, { auth: token, data: {type_id: internationalOnly ? 'international' : 'national-italy'} });
};

export default { login, rebuildDB, recalculateReelo, recalculatePseudoReelo, recalculateInitReelo, calculatePositions};