import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import CheckoutForm from '../CheckoutForm';


const stripePromise = loadStripe('pk_test_uyMCNsIrheLKqSuXMF37Sm07');

const Step2 = (props) => {

    const {clientSecret, returnInfo, handleBack, language } = props;

    const port = window.location.port;
    const baseURL = `${window.location.protocol}//${window.location.hostname}${port ? ':'+port : ''}/certificato`;
    const queryString = new URLSearchParams();
    queryString.set('name', returnInfo.p.name);
    queryString.set('surname', returnInfo.p.surname);
    queryString.set('id', returnInfo.p.id);
    queryString.set('e', returnInfo.e);
    queryString.set('s', returnInfo.s);
    queryString.set('iip', returnInfo.iip);
    const returnURL = `${baseURL}?${queryString.toString()}`;

    const options = {
        clientSecret: clientSecret,
    };


    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm language={language} handleBack={handleBack} returnURL={returnURL} />
        </Elements>

    );
};


export default Step2;