import { RANKS } from '../constants/i18n';
import { fiveRelevantDigits, toTitleCase } from '../utils/common';


const selectRanksTable = state => {

    const language = state.i18n.language;

    const rows = state.ranks.rows.map((row) => ({
        id: row.player.id,
        position: row.player.position,
        filtered_position: row.player.filtered_position,
        name: toTitleCase(row.player.name),
        surname: toTitleCase(row.player.surname),
        nationality: toTitleCase(row.player.nationality),
        lastCategory: row.last_category,
        reelo: (row.player.reelo).toFixed(2),
    }));

    const columns = [
        { hideOnSmall: true, headerName: RANKS[language].RANKS_TABLE_HEADERS.GLOBAL_POSITION, align: 'center' },
        { hideOnSmall: true, headerName: RANKS[language].RANKS_TABLE_HEADERS.CATEGORY_POSITION, align: 'center' },
        { headerName: RANKS[language].RANKS_TABLE_HEADERS.NAME, align: 'center' },
        { headerName: RANKS[language].RANKS_TABLE_HEADERS.SURNAME, align: 'center' },
        { hideOnSmall: true, headerName: RANKS[language].RANKS_TABLE_HEADERS.NATIONALITY, align: 'center' },
        { hideOnSmall: true, headerName: RANKS[language].RANKS_TABLE_HEADERS.LAST_CAT, align: 'center' },
        { headerName: RANKS[language].RANKS_TABLE_HEADERS.REELO, align: 'center' },
    ];

    const labelDisplayedRows = (page) => ({ from, to, count }) => RANKS[language].RANKS_TABLE_FOOTER.LABEL_DISPLAYED_ROWS
        .replace('{from}', from)
        .replace('{to}', to)
        .replace('{count}', count)
        .replace('{page}', page)
        .replace('{pages}', (count / (to - (from - 1)) + 0.4).toFixed());

    const playersCount = state.ranks.playersCount;

    return { rows, columns, playersCount, labelDisplayedRows };
};


const selectPagination = state => ({ page: state.ranks.page, size: state.ranks.size });

const selectPlayersCount = state => (state.ranks.playersCount);

const selectPlayerDetails = state => {
    const language = state.i18n.language;

    const columns = [
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.YEAR, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.CATEGORY, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.START, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.END, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.IS_INTERNATIONAL, align: 'center' },

        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.EXERCISES, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.SCORE, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.TIME, align: 'center' },
        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.POSITION, align: 'center' },

        { headerName: RANKS[language].DETAILS.TABLE_HEADERS.CITY, align: 'center' },
    ];


    const history = state.ranks.details;
    const selectedPlayer = state.ranks.selectedPlayer;

    return { history, columns, selectedPlayer };
};

// we receive
// {
//     "pseudo_by_year": {
//       "2016-C1-false": {
//         "0_base_score": 255,
//         "1_category_homogenization": 255,
//         "2_score_normalization": 0.970089874421183,
//         "3_multiplicative_factor": 9701,
//         "4_international_results": 9701,
//         "5_category_promotion": 9701,
//         "6_aging": 7959.561026423894
//       },
//       "2016-C1-true": {
//         "0_base_score": 320,
//         "1_category_homogenization": 320,
//         "2_score_normalization": 0.8038365796539141,
//         "3_multiplicative_factor": 8038,
//         "4_international_results": 12057,
//         "5_category_promotion": 12057,
//         "6_aging": 9892.632439500348
//       }
//     },
//     "average": 11654.3831048176,
//     "anti_exploit": 11654.3831048176,
//     "no_participation_penalty": 11654.3831048176
//   }
const selectReeloSteps = state => {
    const steps = state.ranks.steps;
    const language = state.i18n.language;

    const years = [];

    if (!steps.pseudo_by_year) {
        return null;
    }

    for (const [label, pseudoSteps] of Object.entries(steps.pseudo_by_year)) {

        const names = [];
        const values = [];

        for (const [name, value] of Object.entries(pseudoSteps)) {
            names.push(RANKS[language].STEPS[name.substring(2).toUpperCase()]);
            values.push(fiveRelevantDigits(value) < 0 ? RANKS[language].STEPS.INVALID_PROMOTION : fiveRelevantDigits(value));
        }

        const split = label.split('-');
        const parsedLabel = `${split[0]} ${split[1]} ${RANKS[language].STEPS[split[2] === 'true' ? 'IS_INTERNATIONAL' : 'NOT_INTERNATIONAL']}`;
        years.push({ label: parsedLabel, names: names, values: values });
    }


    const reelo = {
        names: [
            RANKS[language].STEPS.AVERAGE,
            RANKS[language].STEPS.ANTI_EXPLOIT,
            RANKS[language].STEPS.NO_PARTICIPATION_PENALTY,
        ],
        values: [
            steps.average.toFixed(1),
            steps.anti_exploit.toFixed(1),
            steps.no_participation_penalty.toFixed(1),
        ],
    };
    return { years, reelo };
};

export default { selectRanksTable, selectPagination, selectPlayersCount, selectPlayerDetails, selectReeloSteps };