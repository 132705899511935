import { compose } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import UploadForm from '../components/Forms/UploadForm';
import BaseContainer from './higher_order/BaseContainer';
import RequireAuth from './higher_order/RequireAuth';
import * as UploadAction from '../stores/upload/UploadAction';
import UploadSelector from '../selectors/UploadSelector';
import ConfirmDialog from '../components/ConfirmDialog';
import LanguageSelector from '../selectors/LanguageSelector';
import { UPLOAD } from '../constants/i18n';



const mapStateToProps = (state) => ({
    fileAlreadyExists: UploadSelector.selectFileAlreadyExist(state),
    language: LanguageSelector.selectLanguage(state),
});

const Upload = (props) => {
    const { language, fileAlreadyExists, dispatch } = props;

    const [bundle, setBundle] = useState({});
    const [open, setOpen] = useState(false);
    const [existenceChecked, setExistenceChecked] = useState(false);

    const onFormSubmit = async formBundle => {
        setOpen(true);
        setBundle(formBundle);
        await dispatch(UploadAction.fileExists(formBundle));
        setExistenceChecked(true);
    };

    const onConfirm = async () => {
        setOpen(false);
        await dispatch(UploadAction.uploadFile(bundle));
        setBundle({});
        setExistenceChecked(false);
    };

    const onCancel = () => {
        setOpen(false);
        setBundle({});
        setExistenceChecked(false);
    };

    return (
        <BaseContainer title={UPLOAD[language].TITLE} description={UPLOAD[language].DESCRIPTION}>
            <br />
            <UploadForm onSubmit={onFormSubmit} />
            <ConfirmDialog
                open={open}
                title={UPLOAD[language].CONFIRM_DIALOG.TITLE}
                message={fileAlreadyExists ? UPLOAD[language].CONFIRM_DIALOG.MESSAGE_EXIST : UPLOAD[language].CONFIRM_DIALOG.MESSAGE_NO_EXIST}
                onConfirm={onConfirm}
                onCancel={onCancel}
                confirmOpt={UPLOAD[language].CONFIRM_DIALOG.CONFIRM}
                cancelOpt={UPLOAD[language].CONFIRM_DIALOG.CANCEL}
                confirmDisabled={!existenceChecked}
            />
        </BaseContainer>
    );

};

export default compose(RequireAuth, connect(mapStateToProps))(Upload);
