import { Checkbox, Input, MenuItem, Select, Typography, Grid, Button } from '@material-ui/core';
import React, { useState } from 'react';
import TextFieldWithDescription from './TextFieldWithDescription';


const UPLOAD = {
    IT: {
        LABEL_FILE: 'Classifica',
        DESCRIPTION_FILE: 'Seleziona il documento contenente la classifica in formato txt',
        LABEL_YEAR: 'Anno',
        DESCRIPTION_YEAR: 'Inserisci l\'anno a cui i risultati fanno riferimento',
        LABEL_CATEGORY: 'Categoria',
        DESCRIPTION_CATEGORY: 'Seleziona la categoria a cui il documento fa riferimento',
        DEFAULT_CATEGORY: 'Seleziona una categoria',
        LABEL_FORMAT: 'Formato Dati',
        DESCRIPTION_FORMAT: 'Inserisci il tipo di dati contenuto nelle colonne in modo ordinato e separato da spazi, i possibili valori sono: "nome", "cognome", "sede", "punti", "tempo", "esercizi" o anche solo le iniziali (n, c, s, p, t, e)',
        LABEL_START: 'Esercizio Iniziale',
        DESCRIPTION_START: 'Specifica da quale eserizio inizia la categoria scelta.',
        LABEL_END: 'Ultimo Esercizio',
        DESCRIPTION_END: 'Seleziona a quale eserizio finisce la categoria scelta.',
        LABEL_IS_INTERNATIONAL: 'Risultato Internazionale',
        DESCRIPTION_IS_INTERNATIONAL: 'Seleziona questa casella se la classifica fa riferimento a risultati internazionali ',
        SUBMIT: 'Carica Classifica'
    }
};

const language = 'IT';

const UploadForm = props => {

    const { onSubmit } = props;

    const [file, setFile] = useState(null);
    const [year, setYear] = useState('');
    const [category, setCategory] = useState('-1');
    const [isInternational, setIsInternational] = useState(false);
    const [format, setFormat] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');


    const handleChange = (fx, type) => event => {
        const fxInput = {
            ['text']: event.target.value,
            ['file']: event.target.files ? event.target.files[0] : null,
            ['checkbox']: event.target.checked,
        }[type];

        fx(fxInput);
    };

    const handleSubmit = event => {
        event.preventDefault();
        onSubmit({ file, year, category, isInternational, format, start, end });
    };


    const selectOptions = [<MenuItem key='-1' value='-1'><em>{UPLOAD[language].DEFAULT_CATEGORY}</em></MenuItem>];

    ['c1', 'c2', 'ce', 'cm', 'l1', 'l2', 'gp', 'hc'].forEach(c => {
        selectOptions.push(<MenuItem key={c} value={c}>{c.toUpperCase()}</MenuItem>);
    });


    return (
        <form onSubmit={handleSubmit}>
            <Grid item container alignContent='center' alignItems="center" spacing={7} xs={12}>
                <Grid item container xs={12}>
                    <Grid item xs={11}>
                        <Typography variant='body1'><em>{UPLOAD[language].LABEL_FILE}</em>: {UPLOAD[language].DESCRIPTION_FILE}</Typography>
                    </Grid>
                    <Input
                        id='file-picker'
                        type='file'
                        onChange={handleChange(setFile, 'file')}
                        disableUnderline
                        required
                    />
                </Grid>

                <Grid item container direction='column' xs={12}>
                    <Grid item xs={11}>
                        <Typography variant='body1'><em>{UPLOAD[language].LABEL_IS_INTERNATIONAL}</em>: {UPLOAD[language].DESCRIPTION_IS_INTERNATIONAL}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={isInternational}
                            onChange={handleChange(setIsInternational, 'checkbox')}
                            value={isInternational}
                            color='primary'
                        />
                    </Grid>
                </Grid>

                <TextFieldWithDescription
                    descriptionOnTop
                    required
                    value={year}
                    description={UPLOAD[language].LABEL_YEAR}
                    descriptionValue={UPLOAD[language].DESCRIPTION_YEAR}
                    onChange={handleChange(setYear, 'text')}
                />

                <Grid item container direction='column' xs={12}>
                    <Grid item xs={12}>
                        <Typography variant='body1'><em>{UPLOAD[language].LABEL_CATEGORY}</em>: {UPLOAD[language].DESCRIPTION_CATEGORY}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            required
                            value={category}
                            onChange={handleChange(setCategory, 'text')}
                            inputProps={{
                                name: UPLOAD[language].LABEL_CATEGORY,
                                id: 'category'
                            }}
                        >
                            {selectOptions}
                        </Select>
                    </Grid>
                </Grid>

                <TextFieldWithDescription
                    descriptionOnTop
                    required
                    value={format}
                    description={UPLOAD[language].LABEL_FORMAT}
                    descriptionValue={UPLOAD[language].DESCRIPTION_FORMAT}
                    onChange={handleChange(setFormat, 'text')}
                />

                <TextFieldWithDescription
                    descriptionOnTop
                    required
                    value={start}
                    description={UPLOAD[language].LABEL_START}
                    descriptionValue={UPLOAD[language].DESCRIPTION_START}
                    onChange={handleChange(setStart, 'text')}
                />

                <TextFieldWithDescription
                    descriptionOnTop
                    required
                    value={end}
                    description={UPLOAD[language].LABEL_END}
                    descriptionValue={UPLOAD[language].DESCRIPTION_END}
                    onChange={handleChange(setEnd, 'text')}
                />

                <Grid item xs={12}>
                    <Button type='submit' variant='contained' color='primary'> {UPLOAD[language].SUBMIT} </Button>
                </Grid>
            </Grid>
        </form>
    );
};




export default UploadForm;