import { BaseModel } from 'sjs-base-model';
import Player from '../_domain/Player';

export default class FetchRanksModel extends BaseModel {

    constructor(data) {
        super();
        this.last_category = '';
        this.player = new Player(data);
        this.update(data);
    }
}