import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AlgorithmForm from '../components/Forms/AlgorithmForm';
import AlgorithmSelector from '../selectors/AlgorithmSelector';
import BaseContainer from './higher_order/BaseContainer';
import RequireAuth from './higher_order/RequireAuth';
import * as AlgorithmAction from '../stores/algorithm/AlgorithmAction';
import { compose } from '@reduxjs/toolkit';
import Routes from '../constants/Routes';
import {ALGORITHM} from '../constants/i18n';
import LanguageSelector from '../selectors/LanguageSelector';

const mapStateToProps = (state) => ({
    currentAlgorithmValues: AlgorithmSelector.selectAlgorithmCurrentValues(state),
    language: LanguageSelector.selectLanguage(state),
});

const Algorithm = (props) => {

    useEffect( () => { props.dispatch(AlgorithmAction.fetch());}, []);

    const onSubmit = async (constants) => {
        await props.dispatch(AlgorithmAction.update(constants));
        props.history.push(Routes.home);
    };

    const { language, currentAlgorithmValues } = props;

    return (
        <BaseContainer title={ALGORITHM[language].TITLE} description={ALGORITHM[language].DESCRIPTION}>
            <AlgorithmForm onSubmit={onSubmit} currentValues={currentAlgorithmValues} />
        </BaseContainer>
    );
};

export default compose(RequireAuth, connect(mapStateToProps))(Algorithm);