import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link } from 'react-router-dom';
import Routes from '../../constants/Routes';
import { NAVBAR } from '../../constants/i18n';
import AuthSelector from '../../selectors/AuthSelector';
import { connect } from 'react-redux';
import { Fade, useScrollTrigger } from '@material-ui/core';
import LanguageSelector from '../../selectors/LanguageSelector';

const mapStateToProps = (state) => ({
    userAuthenticated: AuthSelector.selectIsAuthenticated(state),
    language: LanguageSelector.selectLanguage(state),
});

const innerStyles = makeStyles(theme => createStyles({
    root: {
        '&$selected': {
            backgroundColor: theme.palette.secondary.main,
        },
        textAlign: 'center',
        paddingLeft: '1px',
        paddingRight: '1px',
    },
    selected: {},
}));

const outerStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.primary.main,
        bottom: 0,
        width: '100%',
        position: 'fixed',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
}));

const BotNavigation = (props) => {

    const trigger = useScrollTrigger();
    const { language, userAuthenticated } = props;
    const innerClasses = innerStyles();
    const outerClasses = outerStyles();

    return (
        <Fade appear={false} in={!trigger}>
            <BottomNavigation
                classes={outerClasses}
                showLabels
            >
                <BottomNavigationAction component={Link} to={Routes.home} classes={innerClasses} label={NAVBAR[language].ABOUT} />
                {userAuthenticated && <BottomNavigationAction component={Link} to={Routes.upload} classes={innerClasses} label={NAVBAR[language].UPLOAD} />}
                {userAuthenticated && <BottomNavigationAction component={Link} to={Routes.algorithm} classes={innerClasses} label={NAVBAR[language].ALGORITHM} />}
                {userAuthenticated && <BottomNavigationAction component={Link} to={Routes.namesakes} classes={innerClasses} label={NAVBAR[language].NAMESAKES} />}
                <BottomNavigationAction component={Link} to={Routes.ranks} classes={innerClasses} label={NAVBAR[language].RANKS} />
            </BottomNavigation>
        </Fade>
    );
};

export default connect(mapStateToProps)(BotNavigation);