import Action from '../../utils/Action';
import RanksEffects from './RanksEffects';

export const FETCH_RANKS = 'Ranks.fetch';
export const FETCH_RANKS_COMPLETE = `${FETCH_RANKS}${Action.DONE_SUFFIX}`;
export const FETCH_RANKS_LOADING = `${FETCH_RANKS}${Action.LOADING_SUFFIX}`;

export const fetch = () => async (dispatch, getState) => {
    const { page, size } = getState().ranks;
    const {internationalOnly} = getState().auth;
    await Action.createThunk(dispatch, FETCH_RANKS, RanksEffects.fetch, page, size, internationalOnly);
};

export const SET_RANKS_TABLE_PAGE = 'Ranks.setPage';

export const setPage = (page) => Action.createDefault(SET_RANKS_TABLE_PAGE, { page });

export const SET_RANKS_TABLE_SIZE = 'Ranks.setSize';

export const setSize = (size) => Action.createDefault(SET_RANKS_TABLE_SIZE, { size });

export const COUNT_PLAYERS = 'Ranks.countPlayers';
export const COUNT_PLAYERS_COMPLETE = `${COUNT_PLAYERS}${Action.DONE_SUFFIX}`;
export const COUNT_PLAYERS_LOADING = `${COUNT_PLAYERS}${Action.LOADING_SUFFIX}`;

export const countPlayers = () => async (dispatch, getState) => {
    const {internationalOnly} = getState().auth;
    await Action.createThunk(dispatch, COUNT_PLAYERS, RanksEffects.countPlayers, internationalOnly);
};

export const FILTER_RANKS = 'Ranks.filter';
export const FILTER_RANKS_COMPLETE = `${FILTER_RANKS}${Action.DONE_SUFFIX}`;
export const FILTER_RANKS_LOADING = `${FILTER_RANKS}${Action.LOADING_SUFFIX}`;

export const filter = (name, surname, id) => async (dispatch, getState) => {
    const {internationalOnly} = getState().auth;
    await Action.createThunk(dispatch, FILTER_RANKS, RanksEffects.filter, name, surname, id, internationalOnly);
};

export const FILTER_RANKS_BY_CAT = 'Ranks.filter.byCat';
export const FILTER_RANKS_BY_CAT_COMPLETE = `${FILTER_RANKS_BY_CAT}${Action.DONE_SUFFIX}`;
export const FILTER_RANKS_BY_CAT_LOADING = `${FILTER_RANKS_BY_CAT}${Action.LOADING_SUFFIX}`;

export const filterByCat = (name, surname, id, cat) => async (dispatch, getState) => {
    const {internationalOnly} = getState().auth;
    await Action.createThunk(dispatch, FILTER_RANKS_BY_CAT, RanksEffects.filterByCat, name, surname, id, cat, internationalOnly);
};

export const FETCH_RANKS_DETAILS = 'Ranks.details';
export const FETCH_RANKS_DETAILS_COMPLETE = `${FETCH_RANKS_DETAILS}${Action.DONE_SUFFIX}`;
export const FETCH_RANKS_DETAILS_LOADING = `${FETCH_RANKS_DETAILS}${Action.LOADING_SUFFIX}`;

export const details = (id) => async (dispatch, getState) => {
    const {internationalOnly} = getState().auth;
    await Action.createThunk(dispatch, FETCH_RANKS_DETAILS, RanksEffects.details, id, internationalOnly);
};

export const FETCH_REELO_STEPS = 'Ranks.steps';
export const FETCH_REELO_STEPS_COMPLETE = `${FETCH_REELO_STEPS}${Action.DONE_SUFFIX}`;
export const FETCH_REELO_STEPS_LOADING = `${FETCH_REELO_STEPS}${Action.LOADING_SUFFIX}`;

export const reeloSteps = (id) => async (dispatch, getState) => {
    const {internationalOnly, token} = getState().auth;
    await Action.createThunk(dispatch, FETCH_REELO_STEPS, RanksEffects.reeloSteps, id, token, internationalOnly);
};