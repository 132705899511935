import { Button, Grid } from '@material-ui/core';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import Steps from './Steps';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,

    },
    container: {
        maxHeight: '53vh',
        width: '100%'
    },
}));


const DetailsContainer = (props) => {
    const { columns, history, language, onReelo, steps } = props;
    const classes = useStyles();

    const renderHeader = () =>
        columns.map((column) => (
            <TableCell className={classes.root} align={column.align} key={column.headerName}>
                {column.headerName}
            </TableCell>
        ));

    const renderRows = () =>
        history.map((row, i) => (
            <TableRow hover key={i}>
                <TableCell align={columns[1].align}>{row.game.year}</TableCell>
                <TableCell align={columns[2].align}>{row.game.category}</TableCell>
                <TableCell align={columns[3].align}>{row.game.start}</TableCell>
                <TableCell align={columns[4].align}>{row.game.end}</TableCell>
                <TableCell align={columns[4].align}>{row.game.isInternational ? language.YES : language.NO}</TableCell>
                <TableCell align={columns[4].align}>{row.result.exercises}</TableCell>
                <TableCell align={columns[4].align}>{row.result.score}</TableCell>
                <TableCell align={columns[4].align}>{row.result.time === 0 ? '-' :row.result.time }</TableCell>
                <TableCell align={columns[4].align}>{row.result.position}</TableCell>
                <TableCell align={columns[4].align}>{row.city}</TableCell>
            </TableRow>
        ));

    return (

        <Grid container spacing={8} alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>{renderHeader()}</TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={3}>
                <Button variant='contained' color='primary' onClick={onReelo}>{language.REELO_BTN}</Button>
            </Grid>
            {steps && <Steps reelo={steps.reelo} years={steps.years} />}
        </Grid>
    );
};


export default DetailsContainer;