import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

const ADMIN = {
    IT: {
        LOGIN_BTN: 'Accedi',
        PASSWORD: 'Parola chiave',
        EMAIL: 'Posta elettronica',
    }
};

const language = 'IT';

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
    }
}));

const LoginForm = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { onLogin } = props;
    const classes = useStyles();

    const handleSubmit = event => {
        event.preventDefault();
        onLogin(email, password);
    };

    const handleChanges = (fx) => (event) => {
        fx(event.target.value);
    };


    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container direction='column' alignContent='center' alignItems='center' spacing={7}>
                <Grid item xs={12}>
                    <TextField
                        required
                        type='email'
                        label={ADMIN[language].EMAIL}
                        value={email}
                        onChange={handleChanges(setEmail)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        type='password'
                        label={ADMIN[language].PASSWORD}
                        value={password}
                        onChange={handleChanges(setPassword)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button type='submit' variant='contained' color='primary'>{ADMIN[language].LOGIN_BTN}</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;