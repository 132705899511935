import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PROOF } from '../constants/i18n';


export default function VerticalLinearStepper(props) {
    const { steps, activeStep, language, completitionText} = props;

    const handleNext = () => {
        if (steps[activeStep].onNext) {
            steps[activeStep].onNext();
        }
    };

    const handleBack = () => {
        if (steps[activeStep].onBack) {
            steps[activeStep].onBack();
        }
    };

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel optional={step.optional}>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                                {step.content}
                                <div>
                                    {!step.hideNext && <Button
                                        disabled={!step.completed}
                                        variant='contained'
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? PROOF[language].FINISH_BTN : PROOF[language].CONTINUE_BTN}
                                    </Button>}
                                    {index !== 0 && !step.hideBack && <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {PROOF[language].BACK_BTN}
                                    </Button>}
                                    {step.onClear && <Button
                                        disabled={!step.completed}
                                        onClick={step.onClear}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {PROOF[language].CLEAR_BTN}
                                    </Button>}
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>{completitionText}</Typography>
                </Paper>
            )}
        </Box>
    );
}