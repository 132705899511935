import * as AlgorithmAction from './AlgorithmAction';

const INITIAL_STATE = {
    currentValues: {
        year: '',
        kExercise: '',
        pInternational: '',
        fMultiplier: '',
        antiExploit: '',
        noParticipationPenalty: '',
    }
};

const RanksReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [AlgorithmAction.FETCH_CONSTANTS_COMPLETE]: {...state, currentValues: action.payload}
    };
    return reduce[action.type] || { ...INITIAL_STATE, ...state };
};

export default RanksReducer;
