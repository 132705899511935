import * as UploadAction from './UploadAction';


const INITIAL_STATE = {
    form: {},
    exists: false,
};

const UploadReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [UploadAction.FILE_EXISTS_COMPLETE]: { ...state, exists: action.payload?.exists },
        [UploadAction.RANK_UPLOAD_COMPLETE]: { ...state, form: {} }
    };

    return reduce[action.type] || {...INITIAL_STATE, ...state};
};

export default UploadReducer;