import Action from '../../utils/Action';
import UploadEffects from './UploadEffects';

export const FILE_EXISTS = 'upload.FileExist';
export const FILE_EXISTS_COMPLETE = `${FILE_EXISTS}${Action.DONE_SUFFIX}`;
export const FILE_EXISTS_LOADING = `${FILE_EXISTS}${Action.LOADING_SUFFIX}`;

export const fileExists = ({ year, category, isInternational }) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, FILE_EXISTS, UploadEffects.fileExists, token, year, category, isInternational);
};

export const RANK_UPLOAD = 'upload.Rank';
export const RANK_UPLOAD_COMPLETE = `${RANK_UPLOAD}${Action.DONE_SUFFIX}`;
export const RANK_UPLOAD_LOADING = `${RANK_UPLOAD}${Action.LOADING_SUFFIX}`;

export const uploadFile = (formBundle) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, RANK_UPLOAD, UploadEffects.uploadFile, token, formBundle);
};