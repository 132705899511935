import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: theme.palette.primary.main,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
}));

export default function CircularProgressWithLabel(props) {
    const classes = useStyles();

    const { percentage, total, current } = props;
    return (
        <Box position="relative" display="inline-flex">
            <div className={classes.root}>
                <CircularProgress className={classes.bottom} variant="determinate" value={100} />
                <CircularProgress className={classes.top} variant="determinate" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{percentage ? `${props.value}%` : `${current} / ${total}`}</Typography>
                </Box>
            </div>
        </Box>
    );
}