import * as NamesakesAction from './NamesakesAction';

const INITIAL_STATE = {
    all: []
};

const NamesakesReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [NamesakesAction.FETCH_NAMESAKES_COMPLETE]: { ...state, all: action.payload?.namesakes },
    };

    return reduce[action.type] || { ...INITIAL_STATE, ...state };
};

export default NamesakesReducer;


