import React, { useEffect, useState } from 'react';
import BaseContainer from './higher_order/BaseContainer';
import { PROOF, COMMON } from '../constants/i18n';
import LanguageSelector from '../selectors/LanguageSelector';
import { connect } from 'react-redux';

import * as RanksAction from '../stores/ranks/RanksAction';
import * as PaymentsAction from '../stores/payments/PaymentsAction';
import RanksSelector from '../selectors/RanksSelector';
import AuthSelector from '../selectors/AuthSelector';
import { DEFAULT_PAGE_FETCH_SIZE } from '../constants/pagination';
import { Typography, } from '@material-ui/core';
import VerticalLinearStepper from '../components/VerticalStepper';
import Step0 from '../components/Steps/0';
import Step1 from '../components/Steps/1';
import ErrorSelector from '../selectors/ErrorSelector';
import Step2 from '../components/Steps/2';
import PaymentsSelector from '../selectors/PaymentsSelector';
import RequireAuth from './higher_order/RequireAuth';
import { compose } from '@reduxjs/toolkit';


const mapStateToProps = (state) => ({
    ranksTable: RanksSelector.selectRanksTable(state),
    pagination: RanksSelector.selectPagination(state),
    playersCount: RanksSelector.selectPlayersCount(state),
    language: LanguageSelector.selectLanguage(state),
    isAuthenticated: AuthSelector.selectIsAuthenticated(state),
    internationalOnly: AuthSelector.selectInternationalOnly(state),
    isSelected: AuthSelector.selectInternationalOnly(state),
    error: ErrorSelector.selectError(state),
    clientSecret: PaymentsSelector.selectClientSecret(state),
    invoiceID: PaymentsSelector.selectInvoiceID(state)
});

const Proof = ((props) => {
    var details = {};
    if (window.location.search) {
        const qp = new URLSearchParams(window.location.search);
        details = {
            p: {
                name: qp.get('name'),
                surname: qp.get('surname'),
                id: qp.get('id')
            },
            s: parseInt(qp.get('s'), 10),
            e: qp.get('e'),
            iip: qp.get('iip')
        };
    }

    const { clientSecret, error, isAuthenticated, language, pagination, ranksTable, playersCount, internationalOnly, isSelected } = props;
    const description = (
        <em>
            {PROOF[language].DESCRIPTION}
        </em>
    );

    const [searching, setSearching] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(details.p || null);
    const [email, setEmail] = useState(details.e || '');
    const [confirmEmail, setConfirmEmail] = useState(details.e || '');
    const [step, setStep] = useState(details.s || 0);
    const [invoiceID] = useState(details.iip || props.invoiceID || '');



    useEffect(() => {
        if (step == 0) {
            props.dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
            props.dispatch(RanksAction.countPlayers());
        }
    }, []);

    useEffect(() => {
        if (step == 0) {
            props.dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
            props.dispatch(RanksAction.countPlayers());
            props.dispatch(RanksAction.fetch());
        }
    }, [isSelected]);

    useEffect(() => {
        if (!searching && step == 0) {
            props.dispatch(RanksAction.fetch());
        }
    }, []);

    if (!isAuthenticated) {
        return (
            <BaseContainer props={props} title='Reelo' description={description}>
                <Typography >
                    {COMMON[language].WIP}
                </Typography>
            </BaseContainer>
        );
    }

    const stepsLanguage = PROOF[language].INSTRUCTIONS;

    const onClear = () => {
        setStep(step - 1);
        setSearching(false);
        setSelectedPlayer(null);
    };

    const onNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const onBack = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const content0 = <Step0
        language={language}
        dispatch={props.dispatch}
        searching={searching}
        selectedPlayer={selectedPlayer}
        ranksTable={ranksTable}
        pagination={pagination}
        internationalOnly={internationalOnly}
        playersCount={playersCount}
        setSearching={setSearching}
        setSelectedPlayer={setSelectedPlayer}
        error={error}
    />;

    const handleSetEmail = (event) => {
        setEmail(event.target.value);
    };

    const content1 = <Step1
        onChange={handleSetEmail}
        value={email}
    />;

    const content2 = <Step2
        language={language}
        clientSecret={clientSecret}
        returnInfo={{
            p: { ...selectedPlayer },
            s: 3,
            e: email,
            iip: props.invoiceID
        }}
        handleBack={onBack}
    />;

    return (
        <BaseContainer onErrorClear={() => setSearching(false)} props={props} title='Reelo' description={description}>
            <VerticalLinearStepper
                language={language}
                activeStep={step}
                completitionText={`${PROOF[language].COMPLETED} ${PROOF[language].YOUR_CODE} ${invoiceID}`}
                steps={[
                    {
                        label: stepsLanguage[0].LABEL,
                        description: selectedPlayer ? '' : stepsLanguage[0].DESCRIPTION,
                        content: content0,
                        completed: selectedPlayer,
                        optional: selectedPlayer ? `${selectedPlayer.name} ${selectedPlayer.surname}` : '',
                        onClear: onClear,
                        onNext: onNext,
                        onBack: onBack
                    },
                    {
                        label: stepsLanguage[1].LABEL,
                        description: stepsLanguage[1].DESCRIPTION,
                        optional: confirmEmail ?? '',
                        content: content1,
                        onBack: onBack,
                        onNext: async () => {
                            onNext();
                            setConfirmEmail(email);
                            details.e = email;
                            await props.dispatch(PaymentsAction.createIntent(email, selectedPlayer.id, language));
                        },
                        // eslint-disable-next-line no-useless-escape
                        completed: (/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/).test(email)
                    },
                    {
                        label: stepsLanguage[2].LABEL,
                        description: stepsLanguage[2].DESCRIPTION,
                        content: content2,
                        onNext: onNext,
                        onBack: onBack,
                        hideNext: true,
                        hideBack: true
                    },
                    {
                        label: stepsLanguage[3].LABEL,
                        description: stepsLanguage[3].DESCRIPTION,
                        completed: true,
                        hideBack: true,
                        onNext: onNext
                    }
                ]}
            />
        </BaseContainer>
    );
});

export default compose(RequireAuth, connect(mapStateToProps))(Proof);