import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, makeStyles, IconButton, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Routes from '../../constants/Routes';
import AuthSelector from '../../selectors/AuthSelector';
import { NAVBAR } from '../../constants/i18n';
import NavigationBtn from './NavigationBtn';
import * as AuthAction from '../../stores/auth/AuthAction';
import * as LanguageAction from '../../stores/language/LanguageAction';
import LanguageSelector from '../../selectors/LanguageSelector';
import flag_it from '../../assets/images/flag_it.svg';
import flag_gb from '../../assets/images/flag_gb.svg';
import NavigationSelector from '../../selectors/NavigationSelector';

const mapStateToProps = (state) => ({
    userAuthenticated: AuthSelector.selectIsAuthenticated(state),
    language: LanguageSelector.selectLanguage(state),
    location: NavigationSelector.selectLocation(state)
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: '10px',
        backgroundColor: theme.palette.primary,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    menuButton: {
        right: '24px',
        position: 'absolute',
        display: 'flex',
    },
    navbtn: {
        marginRight: theme.spacing(3),
    },
}));

const TopNavigation = props => {
    const { location, language, userAuthenticated } = props;

    const onLogout = () => props.dispatch(AuthAction.logout());

    const classes = useStyles();

    const loginBtn = (<Button
        component={Link}
        to={Routes.admin}
        color="secondary">
        <Typography color="secondary" variant="subtitle1">
            {NAVBAR[language].LOGIN}
        </Typography>
    </Button>);

    const logoutBtn =(
        <Button
            component={Link}
            to={Routes.home}
            onClick={onLogout}
            color="secondary">
            <Typography color="secondary" variant="subtitle1">
                {NAVBAR[language].LOGOUT}
            </Typography>
        </Button>
    );


    // TODO: update to use multiple languages
    // TODO: fix icon style
    const languageBtn = (
        <IconButton
            size='small'
            component={Link}
            to={location}
            onClick={() => props.dispatch(LanguageAction.setLanguage(language === 'IT' ? 'EN' : 'IT'))}
            color="secondary"
        >
            <Icon fontSize='small'>
                <img src={language === 'IT' ? flag_gb : flag_it} />
            </Icon>
        </IconButton>
    );

    const renderLabel = label => (<Typography color="secondary" variant="subtitle1">{label}</Typography>);

    const renderNavButtons = () => [
        { label: NAVBAR[language].ABOUT, hide: false, route: Routes.home },
        { label: NAVBAR[language].UPLOAD, hide: !userAuthenticated, route: Routes.upload },
        { label: NAVBAR[language].ALGORITHM, hide: !userAuthenticated, route: Routes.algorithm },
        { label: NAVBAR[language].NAMESAKES, hide: !userAuthenticated, route: Routes.namesakes },
        { label: NAVBAR[language].ADMIN, hide: !userAuthenticated, route: Routes.admin },
        { label: NAVBAR[language].RANKS, hide: false, route: Routes.ranks },
    ].map(btn => (<NavigationBtn key={btn.label} Component={Button} SubComponent={Link} label={renderLabel(btn.label)} route={btn.route} className={classes.navbtn} hide={btn.hide} />));


    return (
        <div className={classes.root}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    {renderNavButtons()}
                    <div>{languageBtn}</div>
                    <div className={classes.menuButton}>{userAuthenticated ? logoutBtn : loginBtn}</div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default connect(mapStateToProps)(TopNavigation);