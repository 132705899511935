import * as PaymentsAction from './PaymentsAction';

const INITIAL_STATE = {
    client_secret: ''
};

const PaymentsReducer = (state = INITIAL_STATE, action) => {
    const reduce = {
        [PaymentsAction.CREATE_PAYMENT_INTENT_COMPLETE]: {
            ...state, client_secret: action.payload?.client_secret,
            invoice_id: action.payload?.invoice_id
        },
    };

    return reduce[action.type] || { ...INITIAL_STATE, ...state };
};

export default PaymentsReducer;
