
export const port = 9999;

export const baseURL = `${window.location.protocol}//${window.location.hostname}:${port}`;

export const endpoints = {
    Constants: '/constants',
    Games: '/games', // ?only=years
    Files: '/files',
    FilesByYearAndCategory: '/files/years/{year}/categories/{category}/internationals/{international}',
    Rebuild: '/rebuild',
    Namesakes: '/namesakes',
    Namesake: '/namesakes/{namesakeID}',
    Players: '/players', // ?only=count
    PlayersPositions: '/players/positions',
    PlayersParticipations: '/players/{playerID}/participations',
    ReeloSteps: '/reelo/steps', // ?player_id=123
    ReeloCalculate: {
        Full: '/reelo/full/calculate',
        Init: '/reelo/init/calculate',
        Pseudo: '/reelo/pseudo/calculate',
    },
    UserLogin: '/users/login',
    Ranks: '/ranks', // ?name=abc&surname=abc&player_id=123 || ?last=abc,
    Payments: '/payments'
};
