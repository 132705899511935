import { endpoints } from '../../constants/endpoints';
import { DEFAULT_PAGE, DEFAULT_PAGE_FETCH_SIZE } from '../../constants/pagination';
import FetchRanksModel from '../../models/ranks/FetchRanksModel';
import CountPlayersModel from '../../models/ranks/CountPlayersModel';
import Model from '../../utils/Model';
import Participation from '../../models/_domain/Participation';
import ReeloSteps from '../../models/ranks/ReeloSteps';


const parseInternationalOnly = (internationalOnly) => `&type=${internationalOnly ? 'international' : 'national-italy'}`;

const fetch = async (page = DEFAULT_PAGE, size = DEFAULT_PAGE_FETCH_SIZE, internationalOnly) => {
    const url = `${endpoints.Ranks}?page=${page}&size=${size}${parseInternationalOnly(internationalOnly)}`;
    return await Model.fromGet(FetchRanksModel, url);
};

const countPlayers = async (internationalOnly) => {
    const url = `${endpoints.Players}?only=count${parseInternationalOnly(internationalOnly)}`;
    return await Model.fromGet(CountPlayersModel, url);
};

const filter = async (name = '', surname = '', id = '', internationalOnly) => {
    const url = `${endpoints.Ranks}?name=${name}&surname=${surname}&player_id=${id}${parseInternationalOnly(internationalOnly)}`;
    return await Model.fromGet(FetchRanksModel, url);
};

const filterByCat = async (name = '', surname = '', id = '', cat, internationalOnly) => {
    var qs =  `?category=${cat}`;
    if (name != '') {
        qs = `${qs}&name=${name}`;
    }
    if (surname != '') {
        qs = `${qs}&surname=${surname}`;
    }
    if (id != '') {
        qs = `${qs}&id=${id}`;
    }

    const url = `${endpoints.Ranks}${qs}${parseInternationalOnly(internationalOnly)}`;
    return await Model.fromGet(FetchRanksModel, url);
};

const details = async (id = '', internationalOnly) => {
    const url = `${endpoints.PlayersParticipations.replace('{playerID}', id)}?type=${internationalOnly ? 'international' : 'national-italy'}`;
    return await Model.fromGet(Participation, url);
};

const reeloSteps = async (id, token, internationalOnly) => {
    const url = `${endpoints.ReeloSteps}?player_id=${id}${parseInternationalOnly(internationalOnly)}`;
    return await Model.fromGet(ReeloSteps, url, { auth: token });
};


export default { fetch, filter, details, countPlayers, reeloSteps, filterByCat };