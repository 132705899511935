import { endpoints } from '../../constants/endpoints';

import Model from '../../utils/Model';
import { BaseModel } from 'sjs-base-model';
import FetchNamesakesModel from '../../models/namesakes/FetchNamesakesModel';

const fetch = async (token) => {
    return await Model.fromGet(FetchNamesakesModel, endpoints.Namesakes, { auth: token });
};


const merge = async (token, src, tgt) => {
    const params = {
        auth: token,
        data: {
            src_player_id: Number(src),
            tgt_player_id: Number(tgt),
        },
    };
    return await Model.fromPatch(BaseModel, endpoints.Namesakes, params);
};

const solve = async (token, namesake, player, participation) => {
    const params = {
        auth: token,
        data: {
            namesake: namesake,
            player: player,
            participation: participation,
        },
    };
    return await Model.fromPost(BaseModel, endpoints.Namesake.replace('{namesakeID}', namesake), params);
};

const solveToNew = async (token, namesake, participation) => {
    const params = {
        auth: token,
        data: {
            namesake: namesake,
            participation: participation,
        },
    };
    return await Model.fromPut(BaseModel, endpoints.Namesake.replace('{namesakeID}', namesake), params);
};


export default { fetch , merge, solve, solveToNew};