import React from 'react';
import TextFieldWithDescription from '../Forms/TextFieldWithDescription';


const Step1 = (props) => {

    const {value, onChange} = props;

    return <TextFieldWithDescription
        label={'Email'}
        required
        value={value}
        onChange={onChange}
    />;

};

export default Step1;