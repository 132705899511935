import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import React from 'react';
import DetailsList from './DetailsList';


// expects history: an array of entries. Each entry has structure:
// {player, year, game, result, city}
const PossibilityDisplay = (props) => {

    const { history, labels, player } = props;

    const renderHistory = (h) => h.map((entry, i) => (
        <Grid key={i} item>
            <Card variant="outlined" raised>
                <CardContent>
                    <DetailsList labels={labels} year={entry.game.year} category={entry.game.category} city={entry.city.length > 0 ? entry.city : 'N/A'} score={entry.result.score} position={entry.result.position} />
                </CardContent>
            </Card>
        </Grid>
    ));

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
                <Typography >{labels.possibility + ' ' } <b>{player.id}</b></Typography>
            </Grid>
            {renderHistory(history)}
        </Grid>
    );
};

export default PossibilityDisplay;
