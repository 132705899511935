import { combineReducers } from '@reduxjs/toolkit';
import ErrorReducer from './errors/ErrorReducer';
import LoadingReducer from './loading/LoadingReducer';
import RanksReducer from './ranks/RanksReducer';
import UploadReducer from './upload/UploadReducer';
import AuthReducer from './auth/AuthReducer';
import AlgorithmReducer from './algorithm/AlgorithmReducer';
import LanguageReducer from './language/LanguageReducer';
import NavigationReducer from './navigation/NavigationReducer';
import NamesakesReducer from './namesakes/NamesakesReducer';
import PaymentsReducer from './payments/PaymentsReducer';

export default combineReducers({
    upload: UploadReducer,
    ranks: RanksReducer,
    loading: LoadingReducer,
    errors: ErrorReducer,
    auth: AuthReducer,
    algorithm: AlgorithmReducer,
    navigation: NavigationReducer,
    i18n: LanguageReducer,
    namesakes: NamesakesReducer,
    payments: PaymentsReducer,
});