import { BaseModel } from 'sjs-base-model';

export default class ReeloSteps extends BaseModel {

    constructor(data) {
        super(data);
        this.pseudo_by_year = data.pseudo_by_year;
        this.category_promotion = data.category_promotion;
        this.aging = data.aging;
        this.average = data.average;
        this.anti_exploit = data.anti_exploit;
        this.no_participation_penalty = data.no_participation_penalty;
    }
}
