import React from 'react';
import BaseContainer from './higher_order/BaseContainer';
import { HOME } from '../constants/i18n';
import LanguageSelector from '../selectors/LanguageSelector';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    language: LanguageSelector.selectLanguage(state),
});

const Home = ((props) => {

    const { language } = props;

    const description = (
        <em>
            {HOME[language].DESCRIPTION}
        </em>
    );

    return (
        <BaseContainer props={props} title='Reelo' description={description}>
            <dl>
                <dt>
                    <strong> {HOME[language].CREATION}:</strong>
                </dt>
                <dd> Cesco Reale.</dd>
                <br />

                <dt>
                    <strong>{HOME[language].DIRECTIVE_COMMITTEE}: </strong>
                </dt>
                <dd>Fabio Angelini, Edoardo Canobbio, Cesco Reale</dd>
                <br />

                <dt>
                    <strong>{HOME[language].SCIENTIFIC_COMMITTEE}: </strong>
                </dt>
                <dd>Marco Broglia, Andrea Nari, Marco Pellegrini, Cesco Reale.</dd>
                <br />

                <dt>
                    <strong>{HOME[language].IMPLEMENTATION}: </strong>
                </dt>
                <dd>Edoardo Canobbio.</dd>
                <br />

                <dt>
                    <strong>{HOME[language].TECH_COMMITTEE}: </strong>
                </dt>
                <dd>
                    Fabio Angelini, Anna Bernardi, Edoardo Canobbio, Mirko Cappuccia.
                </dd>
                <br />

                <dt>
                    <strong>{HOME[language].SCIENTIFIC_HELP}: </strong>
                </dt>
                <dd>
                    David Barbato, Maurizio De Leo, Francesco Morandin, Alberto Saracco.
                </dd>
                <br />

                <dt>
                    <strong>{HOME[language].TECH_HELP}: </strong>
                </dt>
                <dd>
                    Diego Corsatto, Carlo Frittoli, Alessio Palmero Aprosio, Elia Scandaletti
                </dd>
            </dl>
        </BaseContainer>
    );
});

export default connect(mapStateToProps)(Home);