import Action from '../../utils/Action';
import AlgorithmEffects from './AlgorithmEffects';

export const FETCH_CONSTANTS = 'Algorithm.fetch';
export const FETCH_CONSTANTS_COMPLETE = `${FETCH_CONSTANTS}${Action.DONE_SUFFIX}`;
export const FETCH_CONSTANTS_LOADING = `${FETCH_CONSTANTS}${Action.LOADING_SUFFIX}`;

export const fetch = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, FETCH_CONSTANTS, AlgorithmEffects.fetch, token);
};

export const UPDATE_CONSTANTS = 'Algorithm.update';
export const UPDATE_CONSTANTS_COMPLETE = `${UPDATE_CONSTANTS}${Action.DONE_SUFFIX}`;
export const UPDATE_CONSTANTS_LOADING = `${UPDATE_CONSTANTS}${Action.LOADING_SUFFIX}`;

export const update = (constants) => async (dispatch, getState) => {
    const token = getState().auth.token;
    await Action.createThunk(dispatch, UPDATE_CONSTANTS, AlgorithmEffects.update, constants, token);
};