import React, { useState } from 'react';
import { Button, Collapse, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import PossibilityDisplay from './PossibilityDisplay';
import { toTitleCase } from '../../utils/common';
import DetailsList from './DetailsList';


const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: theme.palette.primary.main,
        padding: '20px',
        cursor: 'pointer',
    },
    paper: {
        padding: '20px',
        cursor: 'pointer',
    },
    root: {
        marginTop: '10px',
    },

}));

// expects possibilities: an array of arrays, each array has structure:
// {player, game, result, city}
// expects line: an object with structure:
// {name, surname, year, entry, category, city, score, position}
const NamesakeSolver = (props) => {
    const [selected, setSelected] = useState(-1);
    const [display, setDisplay] = useState(true);

    const classes = useStyles();
    const { onSolveToNew, possibilities, namesake, namesakeParticipation, labels, btnSolve, btnNewSolve, onSolve } = props;

    const onClick = (n) => () => {
        if (selected === n) {
            setSelected(-1);
            return;
        }
        setSelected(n);
    };
    const handleSolve = (isNew) => () => {
        setDisplay(false);
        if (isNew) {
            return onSolveToNew(namesake, namesakeParticipation);
        }
        onSolve(namesake, possibilities[selected].player, namesakeParticipation);
    };


    const renderPossibilities = () => possibilities.map((solution, i) => (
        <Grid item xs={12} onClick={onClick(i)} key={i}>
            <Paper elevation={5} className={selected === i ? classes.selected : classes.paper} >
                <PossibilityDisplay history={solution.history} player={solution.player} labels={labels} />
            </Paper>
        </Grid>
    ));

    return (
        <Collapse in={display} unmountOnExit >
            <Grid className={classes.root} item container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h6"><b>{toTitleCase(namesake.name + ' ' + namesake.surname)}</b></Typography>
                    <hr />
                    <DetailsList
                        labels={labels}
                        isInternational={namesakeParticipation.game.isInternational}
                        year={namesakeParticipation.game.year}
                        category={namesakeParticipation.game.category}
                        city={namesakeParticipation.city.length > 0 ? namesakeParticipation.city : 'N/A'}
                        score={namesakeParticipation.result.score}
                        position={namesakeParticipation.result.position} />
                </Grid>
                <Grid item container spacing={4}>
                    {renderPossibilities()}
                </Grid>
                <Grid xs={6} item container justifyContent='center'>
                    <Button onClick={handleSolve(false)} variant='contained' color='primary' disabled={selected === -1}>
                        {btnSolve}
                    </Button>
                </Grid>
                <Grid xs={6} item container justifyContent='center'>
                    <Button onClick={handleSolve(true)} variant='contained' color='primary' disabled={selected !== -1}>
                        {btnNewSolve}
                    </Button>
                </Grid>

            </Grid>
        </Collapse>
    );
};

export default NamesakeSolver;