import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const DetailsList = props => {

    const { labels, isInternational, year, category, city, score, position } = props;

    return (
        <Grid item xs={12}>
            <Typography><b>{labels.year}:</b> {year}</Typography>
            <Typography><b>{labels.category}:</b> {category}</Typography>
            <Typography><b>{labels.city}:</b> {city}</Typography>
            <Typography><b>{labels.score}:</b> {score}</Typography>
            <Typography><b>{labels.position}:</b> {position}</Typography>
            <Typography><b>{labels.isInternational}:</b> {isInternational ? labels.international : labels.national }</Typography>
        </Grid>
    );
};

export default DetailsList;