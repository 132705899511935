import { BaseModel } from 'sjs-base-model';

export default class Player extends BaseModel {

    constructor(data) {
        super();
        this.id = 0;
        this.name = '';
        this.surname = '';
        this.reelo = 0;
        this.accent = '';
        this.nationality = '';
        this.position = 0;
        this.filtered_position = 0;
        this.update(data);
    }
}