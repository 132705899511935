import { BaseModel } from 'sjs-base-model';

export default class FetchAlgorithmModel extends BaseModel {

    constructor(data) {
        super();
        this.year = '';
        this.kExercise = '';
        this.pInternational = '';
        this.fMultiplier = '';
        this.antiExploit = '';
        this.noParticipationPenalty = '';
        this.update(data);
    }
}