import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ErrorDialog from '../../components/ErrorDialog';
import LoadingIcon from '../../components/LoadingIcon';
import Footer from '../../components/Footer';
import * as ErrorAction from '../../stores/errors/ErrorAction';
import * as NavigationAction from '../../stores/navigation/NavigationAction';
import LoadingSelector from '../../selectors/LoadingSelector';
import ErrorSelector from '../../selectors/ErrorSelector';
import AuthSelector from '../../selectors/AuthSelector';
import { COMMON } from '../../constants/i18n';
import LanguageSelector from '../../selectors/LanguageSelector';
import { Alert } from '@mui/material';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({
    title: {
        marginTop: '28px',
        marginBottom: '15px',
    },
});

const mapStateToProps = (state) => ({
    isLoading: LoadingSelector.selectIsLoading(state),
    error: ErrorSelector.selectError(state),
    internationalOnly: AuthSelector.selectInternationalOnly(state),
    isAuthenticated: AuthSelector.selectIsAuthenticated(state),
    language: LanguageSelector.selectLanguage(state)
});

const BaseContainer = (props) => {
    const { onErrorClear, internationalOnly, language, title, error, children, description, isLoading, overrideLoadingFx, skipLoading } = props;

    useEffect(() => {
        props.dispatch(NavigationAction.setNavLocation(`${window.location.pathname}${window.location.search}`));
    }, []);

    const classes = useStyles();

    const renderErrorMsg = () => (
        <Typography component={'div'} align='left' varian='body2' color='error'>
            <b>{COMMON[language].ERROR.MESSAGE}:</b> {error.message.split('\n').map(l => <p key={l}>{l}</p>)}
            <br />
            <b>{COMMON[language].ERROR.CODE}:</b> <p>{error.code}</p>
        </Typography>
    );

    const handleErrorDismiss = () => {
        if (onErrorClear) {
            onErrorClear();
        }
        props.dispatch(ErrorAction.clear());

    };
    const renderError = () => (
        <ErrorDialog
            open={Boolean(error)}
            onClose={handleErrorDismiss}
            title={COMMON[language].ERROR.TITLE}
            message={renderErrorMsg()} />
    );


    const renderLoading = () => overrideLoadingFx ? overrideLoadingFx() : (<LoadingIcon isLoading={isLoading} />);

    const renderDescription = () => description ? (<Typography variant='subtitle1'>{description}</Typography>) : null;

    const renderTitle = () => title ? (<Typography variant="h4" className={classes.title}>{title}</Typography>) : null;

    const handleContent = () => skipLoading ? children : isLoading ? renderLoading() : children;

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={10}>
                {internationalOnly && <Alert severity="warning">{COMMON[language].WARNING_USING_INTERNATIONAL}</Alert>}
                {renderTitle()}
                {renderDescription()}
                <br />
            </Grid>

            <Grid item container xs={10} justifyContent={isLoading ? 'center' : null}>
                {error ? renderError() : null}
                {handleContent()}
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{paddingTop: 10}}/>
            </Grid>
            <Grid item container xs={10} justifyContent='center'>
                <Footer language={language} />
            </Grid>
        </Grid>
    );
};

export default connect(mapStateToProps)(BaseContainer);