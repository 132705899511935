

import { BaseModel } from 'sjs-base-model';

export default class Result extends BaseModel {

    constructor(data) {
        super();
        this.id = 0;
        this.exercises = 0;
        this.time = 0;
        this.score = 0;
        this.position = 0;
        this.pseudoReelo = 0;
        this.update(data);
    }
}