import React from 'react';

const NavigationBtn = props => {

    const { Component, SubComponent, route, label, className, hide } = props;


    return (
        hide ? null :
            <Component
                className={className}
                component={SubComponent}
                to={route}
                color="inherit">
                {label}
            </Component>
    );
};

export default NavigationBtn;