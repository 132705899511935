import { endpoints } from '../../constants/endpoints';
import FileExistModel from '../../models/upload/FileExistModel';
import Model from '../../utils/Model';
import { BaseModel } from 'sjs-base-model';
import Http from '../../utils/Http';
import Game from '../../models/_domain/Game';


const fileExists = async (token, year, category, international) => {
    const url = endpoints.FilesByYearAndCategory
        .replace('{year}', year)
        .replace('{category}', category)
        .replace('{international}', international);

    return await Model.fromGet(FileExistModel, url, { auth: token });
};


const uploadFile = async (token, { year, category, start, end, file, isInternational, format }) => {
    const formData = new FormData();
    formData.append('file', file);

    const data = {
        game: new Game({ year: parseInt(year), category, start: parseInt(start), end: parseInt(end), isInternational }),
        format: _normalizeFormat(format),
    };
    formData.append('data', JSON.stringify(data));

    const params = {
        auth: token,
        contentType: Http.ContentType.MultipartFormData,
        data: formData
    };

    return await Model.fromPut(BaseModel, endpoints.Files, params);
};


function _normalizeFormat(format) {
    const validInputs = {
        ['n']: Format.NAME,
        ['nome']: Format.NAME,

        ['c']: Format.SURNAME,
        ['cognome']: Format.SURNAME,

        ['s']: Format.CITY,
        ['sede']: Format.CITY,
        ['città']: Format.CITY,
        ['cittá']: Format.CITY,
        ['citta']: Format.CITY,

        ['p']: Format.SCORE,
        ['punti']: Format.SCORE,
        ['punteggio']: Format.SCORE,

        ['t']: Format.TIME,
        ['tempo']: Format.TIME,

        ['e']: Format.EXERCISES,
        ['esercizi']: Format.EXERCISES,
    };

    return format.toLowerCase().replace(',', ' ').split(' ').filter(field => validInputs[field]).map(field => validInputs[field]).join(' ');
}


const Format = {
    NAME: 'nome',
    SURNAME: 'cognome',
    CITY: 'città',
    SCORE: 'punti',
    TIME: 'tempo',
    EXERCISES: 'esercizi',
};
export default { fileExists, uploadFile };