import { Button, Grid } from '@material-ui/core';
import React from 'react';

const ADMIN = {
    IT: {
        LOGOUT: 'Esci'
    }
};

const language = 'IT';

const LogoutBtn = props => {
    const { onLogout } = props;

    return (
        <Grid item container justifyContent='center' xs={12}>
            <Button variant="outlined" onClick={onLogout}>
                {ADMIN[language].LOGOUT}
            </Button>
        </Grid>
    );
};

export default LogoutBtn;