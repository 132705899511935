import React, { useState } from 'react';
import { Button, Collapse, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { SearchOff, ExpandMore, Search, ExpandLess } from '@mui/icons-material';


const GridItemXs12 = (props) => <Grid item xs={12} {...props} />;

const ExpandMoreBtn = styled((props) => {
    return <IconButton {...props} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const SearchBar = (props) => {
    const { hideClear, hideID, hideCategory, category, setCategory, language, onSearch, onClear, className } = props;

    const [searchOpen, setSearchOpen] = useState(true);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [id, setId] = useState('');
    const [disabled, setDisabled] = useState(true);

    const validateName = (v) => v.length < 3 && surname.length < 3 && id.length == 0;
    const validateSurname = (v) => v.length < 3 && name.length < 3 && id.length == 0;
    const validateId = (v) => v.length == 0 && name.length < 3 && surname.length < 3;

    const handleSearch = () => {
        onSearch(name, surname, id, category);
    };

    const handleChanges = (setter, validator) => event => {
        const val = event.target.value;
        setter(val);
        setDisabled(validator(val));
    };

    const handleClear = () => {
        setName('');
        setSurname('');
        setId('');
        setDisabled(true);
        onClear();
    };

    const handleSelectCategory = (e) => {
        console.log('selected ', e.target.value);
        setCategory(e.target.value);
        setDisabled(e.target.value === language.ALL_CATEGORIES);
    };

    var itemSize = 2;
    if (hideCategory) {
        itemSize+=1;
    }
    if (hideID) {
        itemSize += 1;
    }
    if (hideClear) {
        itemSize+=1;
    }

    return (
        <Grid container className={className}>
            <Grid item container xs={12} alignItems='baseline' >
                <Grid item>
                    <Typography>{language.TITLE}</Typography>
                </Grid>
                <Grid item>
                    <ExpandMoreBtn expand={`${searchOpen}`} onClick={() => setSearchOpen(!searchOpen)}>
                        {searchOpen ? <ExpandMore /> : <ExpandLess />}
                    </ExpandMoreBtn>
                </Grid>
            </Grid>
            <Collapse component={GridItemXs12} in={searchOpen} timeout="auto" unmountOnExit>
                <form style={{ 'display': 'flex' }}>
                    <Grid container justifyContent='space-around' >
                        {hideClear ? null : (<Grid item xs={itemSize}> <Button variant='contained' color='primary' onClick={handleClear}>{language.CLEAR}</Button></Grid>)}
                        <Grid item xs={itemSize}><TextField onChange={handleChanges(setName, validateName)} value={name} fullWidth label={language.NAME} variant='outlined'></TextField></Grid>
                        <Grid item xs={itemSize}><TextField onChange={handleChanges(setSurname, validateSurname)} value={surname} fullWidth label={language.SURNAME} variant='outlined'></TextField></Grid>
                        {hideID ? null : (<Grid item xs={itemSize}><TextField onChange={handleChanges(setId, validateId)} value={id} fullWidth label={language.ID} variant='outlined'></TextField></Grid>)}
                        {hideCategory? null : (<Select
                            value={category}
                            label="Age"
                            onChange={handleSelectCategory}
                        >
                            {['C1', 'C2', 'L1', 'L2', 'HC', 'GP', language.ALL_CATEGORIES].map(i => (
                                <MenuItem key={i} value={i}>{i}</MenuItem>
                            ))}
                            
                        </Select>)}
                        <Grid item xs={1}>
                            <IconButton type='submit' disabled={disabled} size='medium' color="primary" edge="end" onClick={handleSearch}>
                                {disabled ? <SearchOff /> : <Search />}
                                <Typography> {language.SEARCH} </Typography>
                            </IconButton>
                        </Grid>
                    </Grid>
                </form>
            </Collapse>
        </Grid>
    );
};

export default SearchBar;