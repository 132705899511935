import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

const Steps = (props) => {
    const { years, reelo } = props;


    const renderStep = (stepName, stepValue) =>
        <TableRow key={stepName}>
            <TableCell>{stepName}</TableCell>
            <TableCell align='right'>{stepValue}</TableCell>
        </TableRow>;

    const renderYear = (year) =>
        <Grid item container xs={12} key={year.label}>
            <Grid item xs={12}>
                <Typography variant='h6'>{year.label}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Table key={year.label}>
                    <TableBody>
                        {year.names.map((name, i) => renderStep(name, year.values[i]))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>;



    return (
        <Grid item container xs={10} spacing={4}>
            <Grid item xs={12}>
                <hr />
                <Typography variant='h6'>Pseudo Reelo</Typography>
                <hr />
            </Grid>
            <Grid item container xs={12} spacing={4}>
                {years.map(y => renderYear(y))}
            </Grid>
            <Grid item xs={12}>
                <hr />
                <Typography variant='h6'>Reelo</Typography>
                <hr />
            </Grid>
            <Grid item container xs={12}>
                <Table>
                    <TableBody>
                        {reelo.names.map(
                            (step, i) => renderStep(step, reelo.values[i]))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default Steps;