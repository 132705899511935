import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Grid } from '@material-ui/core';
import { PROOF } from '../constants/i18n';

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);


    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }


        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: props.returnURL,
            },
        });


        if (error) {
            setErrorMessage(error.message);
        } else {
            console.log('yay!');
        }
    };

    return (
        <form onSubmit={() => { }}>
            <PaymentElement />
            <Grid item container spacing={10}>
                <Grid item xs={6}>
                    <Button
                        disabled={!stripe}
                        variant='contained'
                        onClick={handleSubmit}
                        style={{ marginTop: '15px' }}
                    >
                        {PROOF[props.language].PAY_BTN}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={props.handleBack}
                        style={{ marginTop: '15px' }}
                    >
                        {PROOF[props.language].BACK_BTN}
                    </Button>
                </Grid>
            </Grid>

            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

export default CheckoutForm;