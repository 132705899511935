
import Action from '../../utils/Action';
import PaymentsEffects from './PaymentsEffects';


export const CREATE_PAYMENT_INTENT = 'Payments.new_intent';
export const CREATE_PAYMENT_INTENT_COMPLETE = `${CREATE_PAYMENT_INTENT}${Action.DONE_SUFFIX}`;
export const CREATE_PAYMENT_INTENT_LOADING = `${CREATE_PAYMENT_INTENT}${Action.LOADING_SUFFIX}`;

export const createIntent = (email, player, language) => async (dispatch) => {
    await Action.createThunk(dispatch, CREATE_PAYMENT_INTENT, PaymentsEffects.createIntent, email, player, language);
};