import React, { useEffect, useState } from 'react';
import BaseContainer from './higher_order/BaseContainer';
import { connect } from 'react-redux';
import * as RanksAction from '../stores/ranks/RanksAction';
import RanksSelector from '../selectors/RanksSelector';
import AuthSelector from '../selectors/AuthSelector';
import RanksTable from '../components/Ranks/RanksTable';
import LanguageSelector from '../selectors/LanguageSelector';
import { RANKS } from '../constants/i18n';
import SearchBar from '../components/Ranks/SearchBar';
import { DEFAULT_PAGE_FETCH_SIZE } from '../constants/pagination';
import { Grid, makeStyles, Switch } from '@material-ui/core';
import Routes from '../constants/Routes';
import * as AuthAction from '../stores/auth/AuthAction';

const useStyles = makeStyles(theme => ({
    searchBar: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
}));

const mapStateToProps = (state) => ({
    ranksTable: RanksSelector.selectRanksTable(state),
    pagination: RanksSelector.selectPagination(state),
    playersCount: RanksSelector.selectPlayersCount(state),
    language: LanguageSelector.selectLanguage(state),
    isAuthenticated: AuthSelector.selectIsAuthenticated(state),
    internationalOnly: AuthSelector.selectInternationalOnly(state),
    isSelected: AuthSelector.selectInternationalOnly(state),
});

const Ranks = (props) => {
    const { language, pagination, ranksTable, playersCount, internationalOnly, isSelected } = props;

    const classes = useStyles();

    const [searching, setSearching] = useState(false);
    const [filter, setFilter] = useState(RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES);
    const [shouldHidePositionInCat, setShouldHidePositionInCat] = useState(filter === RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES);


    useEffect(() => {
        props.dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
        props.dispatch(RanksAction.countPlayers());
    }, []);

    useEffect(() => {
        props.dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
        props.dispatch(RanksAction.countPlayers());
        props.dispatch(RanksAction.fetch());
    }, [isSelected]);

    useEffect(() => {
        if (!searching) {
            props.dispatch(RanksAction.fetch());
        }
    }, []);

    const onPageChange = (_, page) => {
        props.dispatch(RanksAction.setPage(page + 1));
        props.dispatch(RanksAction.fetch());
    };

    const onSizeChange = (event) => {
        props.dispatch(RanksAction.setPage(1));
        const size = parseInt(event?.target.value, 10);
        props.dispatch(RanksAction.setSize(size));
        props.dispatch(RanksAction.fetch());
    };

    const onSearch = (name, surname, id, c) => {
        if (c !== RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES) {
            console.log('searching by cat', name, surname, id, c);
            setShouldHidePositionInCat(name !== '' || surname !== '' || id !== ''); 
            props.dispatch(RanksAction.filterByCat(name, surname, id, c));
            setFilter(c);
            setSearching(true);
            return;
        }
        console.log('searching by filter', name, surname, id, c);
        setShouldHidePositionInCat(c !== '');
        props.dispatch(RanksAction.filter(name, surname, id));
        setSearching(true);
    };

    const onClear = () => {
        if (searching) {
            setSearching(false);
            setFilter(RANKS[language].SEARCH_OPTIONS.ALL_CATEGORIES);
            props.dispatch(RanksAction.setPage(1));
            props.dispatch(RanksAction.setSize(DEFAULT_PAGE_FETCH_SIZE));
            props.dispatch(RanksAction.fetch());
        }
    };

    const onRowClick = async (player) => {
        // if (!isAuthenticated) {
        //     return;
        // }
        const port = window.location.port.length > 0 ? ':' + window.location.port : '';
        const url = `${window.location.protocol}//${window.location.hostname}${port}${Routes.details}`;
        const qs = `?id=${player.id}&name=${player.name}&surname=${player.surname}&position=${player.position}`;
        window.open(url + qs, '_blank');
    };

    const onSwitch = isChecked => props.dispatch(AuthAction.switchInternationalOnly(isChecked));
    const handleSwitch = (event) => {
        onSwitch(event.target.checked);
    };
    return (
        <BaseContainer title={RANKS[language].TITLE}>
            <SearchBar
                className={classes.searchBar}
                language={RANKS[language].SEARCH_OPTIONS}
                category={filter}
                setCategory={setFilter}
                onSearch={onSearch}
                onClear={onClear} />
            <Grid style={{ paddingBottom: '10px' }} item xs={12}>
                <Switch checked={isSelected} color="primary" onChange={handleSwitch} /> {isSelected ? RANKS[language].SWITCH_INTERNATIONAL_OFF : RANKS[language].SWITCH_INTERNATIONAL_ON}
            </Grid>
            <RanksTable
                hidePositionInCategory={shouldHidePositionInCat}
                showNationality={internationalOnly}
                onRowClick={onRowClick}
                rows={ranksTable.rows}
                columns={ranksTable.columns}
                page={pagination.page}
                pageSize={pagination.size}
                onPageChange={onPageChange}
                onSizeChange={onSizeChange}
                entriesCount={playersCount}
                labelDisplayedRows={ranksTable.labelDisplayedRows}
                labelRowsPerPage={RANKS[language].RANKS_TABLE_FOOTER.LABEL_ROWS_PER_PAGE}
            />
        </BaseContainer>
    );
};

export default connect(mapStateToProps)(Ranks);