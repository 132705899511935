import { BaseModel } from 'sjs-base-model';

export default class CreatePaymentIntentModel extends BaseModel {

    constructor(data) {
        super();
        this.client_secret = '';
        this.invoice_id = '';
        this.update(data);
    }
}